import { useState } from 'react'
import CloseButton from './buttons/CloseButton'
import AlbumCoverImage from './AlbumCoverImage'
import Sparkles from './SparklesEffect'

function TextInput ({ type, placeholder, value, onChange, className, required = false }) {
  return (
    <input 
      type="text" 
      value={value}
      onChange={onChange}
      placeholder={placeholder} 
      className={`${className} bg-black bg-opacity-10 p-3 rounded-xl`}  
      required={required}
    />
  )
}

function Checkbox ({ value, onChange, className, children, required = false }) {
  return (
    <label className="cursor-pointer text-sm sm:text-base">
      <input
        type="checkbox"
        value={value}
        onChange={onChange}  
        className={`${className} mr-3 form-checkbox bg-black bg-opacity-10 w-5 h-5 rounded checked:bg-primary checked:text-primary`}
        required={required}        
      />
      {children}
    </label>
  )
}

export default function SuccessModal ({ isOpen, handleClose, gameIsCompleted, handleSpielregelnClick }) {

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [agbConsent, setAgbConsent] = useState(false);
  const [newsletterConsent, setNewsletterConsent] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const validateEmail = (email) => {
    const regex = /^[\w\.-]+(\+[a-zA-Z0-9]*)?@[a-zA-Z\d\.-]+\.[a-zA-Z]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
      const emailInput = event.target.value;
      setEmail(emailInput);
      setEmailIsValid(validateEmail(emailInput));
  };
  
  const resetForm = () => {
    setSubscriptionStatus(null);
  }

  const handleSubmit = async (event) => {
    if (!emailIsValid) {
      console.log('invalid email');
    };

    event.preventDefault();
    try {
      const response = await fetch('https://baum.minemusik.de/participants.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          'firstname': firstname,
          'lastname': lastname,
          'email': email,
          'agbConsent': agbConsent,
          'newsletterConsent': newsletterConsent
        })
      });
      if (response.status === 200) {
        setSubscriptionStatus('success');
      } else {
        setSubscriptionStatus('error');
      }
    } catch (error) {
      setSubscriptionStatus('error');
      console.error('Error:', error);
    }
  };

  if (!isOpen) return;

  return (
    <>
      {isOpen && ( // dark overlay
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-10" onClick={handleClose}></div>
      )}
      <div 
        className="absolute rounded-xl top-[5vh] w-[90vw] max-w-xl transform ease-in-out duration-300 overflow-hidden z-10"
      >
        <CloseButton className={'absolute z-10 top-3 right-3 sm:top-8 sm:right-8'} handleClose={handleClose} />
        <div className="max-h-[90dvh] flex flex-col items-center pt-24 px-6 sm:px-12 pb-20 text-primary bg-secondary bg-noise overflow-auto">
          { !gameIsCompleted && (
            <>
              <h1 className="text-left w-full text-2xl font-bold mb-10">
                Gewinnspiel
              </h1>
              <section className="pr-5 sm:pr-20">
                <p className="mb-2">
                  Finde zuerst die 5 Zauberblätter, um am Gewinnspiel teilzunehmen! 
                  Schau dir die <button className="underline" onClick={handleSpielregelnClick}>Spielregeln</button> an.
                </p>
                <p>
                  Zu gewinnen gibt es ein Mine-Deluxe-Paket, bestehend aus einem signierten Album in der ltd. Edition (CD oder LP), einem Merchandise Paket (2x T-Shirt, 2x Socken, 1x Cap und 2x Beutel) und 2 Gästelistenplätzen auf einem Mine BAUM-Tour Konzert deiner Wahl, sowie fünf Merchandise Pakete mit T-Shirt, Cap, Socken und Beutel.
                </p>
              </section>
            </>
          )}
          { gameIsCompleted && !subscriptionStatus && (
            <>
              <h1 className="text-2xl mb-10">
                YOU DID IT! Der Baum ist befreit und alles kann wachsen 🌳
              </h1>
              
              <Sparkles>
                <AlbumCoverImage className="border-secondary !w-2/3 m-auto shadow-lg" />
              </Sparkles>
              
              <form 
                className="flex flex-col gap-3 my-10"
                onSubmit={handleSubmit}
              >
                <h2 className="text-xl">
                  Trag dich ein und nimm am Gewinnspiel teil
                </h2>
                <p className="text-base mb-3">
                  Unter allen Teilnehmenden verlosen wir ein Mine-Deluxe-Paket* sowie fünf stylische Merchandise Pakete*.
                </p>
                <TextInput 
                  value={firstname}
                  onChange={(event) => setFirstname(event.target.value)}
                  placeholder="Vorname *"
                  required={true}
                />
                <TextInput 
                  value={lastname}
                  onChange={(event) => setLastname(event.target.value)}
                  placeholder="Nachname *"
                  required={true}
                />
                <TextInput
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="E-Mail *"
                  required={true}
                  className={!emailIsValid ? 'border-2 border-red-500' : ''}
                />
                {!emailIsValid && <p className='text-sm text-red-500'>Bitte gib eine gültige Email Adresse an.</p>}
                
                <span className="mt-3"/>
                <Checkbox 
                  value={agbConsent}
                  onChange={(event) => setAgbConsent(event.target.checked)}
                  required={true}
                >
                  Ja, ich habe die <a className="underline" href="https://www.minemusik.de" target="_blank">Datenschutzbestimmung</a> und die <a className="underline" href="https://www.karakter-worldwide.com/mine-gewinnspiel" target="_blank">AGB</a> gelesen und <span className="whitespace-nowrap">akzeptiere sie *</span>
                </Checkbox>
                <Checkbox
                  value={newsletterConsent}
                  onChange={(event) => setNewsletterConsent(event.target.checked)}
                >
                  Ja, ich möchte den Mine Newsletter erhalten (2-4x pro Jahr)
                </Checkbox>
                
                <button 
                  type="submit"
                  disabled={!emailIsValid}
                  className={`
                    bg-primary text-secondary font-bold p-3 rounded-xl mt-10
                  `}
                >
                  Teilnehmen
                </button>
              </form>
              <small className="text-sm">
                *= Zu gewinnen gibt es ein Mine-Deluxe-Paket, bestehend aus einem signierten Album in der ltd. Edition (CD oder LP), einem Merchandise Paket (2x T-Shirt, 2x Socken, 1x Cap und 2x Beutel) und 2 Gästelistenplätzen auf einem Mine BAUM-Tour Konzert deiner Wahl, sowie fünf Merchandise Pakete mit T-Shirt, Cap, Socken und Beutel.
              </small> 
            </>
          )}
          
          {subscriptionStatus === 'success' && (
            <div className="flex flex-col">
              Check. Jetzt entspannen und Daumen drücken bis gelost wird. 
              <button
                className="bg-primary text-secondary font-bold p-3 rounded-xl mt-10"
                onClick={handleClose}
              >
                Alles klar
              </button>
            </div>
          )}

          {subscriptionStatus === 'error' && (
            <div className="flex flex-col">
              Oh shit! Da ist der Wurm drin 🐛 Du musst es leider nochmal versuchen.
              <button
                className="bg-primary text-secondary font-bold p-3 rounded-xl mt-10"
                onClick={resetForm}
              >
                Nochmal probieren
              </button>
            </div>
          )}
        </div>
      </div>    
    </>
  );
}