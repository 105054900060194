export const baumConfig = {
    name: 'baum',
    appBackground: '#ffffff',
    baseScale: {
      portrait: 0.11,
      landscape: 0.3
    },
    nextLevelThreshold: 502551102609,
    maxScaleLimit: {
      scale: 5988137,
      zoomLevel: 2
    },
    selectedSprite: 'baum1',
    spriteKeys: [
      "baum1", 
      "baum2",
      "baum2a", 
      "baum3", 
      "baum3a", 
      "baum4",
      "baum4a",
      "baum5",
      'baum6Transition',
      "baum6",
      "baum7",
      "baum8",
      "baum9",
      "baum10",
      "baumFinal"
    ],
    spritesRef: {
      baum1: null,
      baum2: null,
      baum2a: null,
      baum2b: null,
      baum3: null,
      baum3a: null,
      baum4: null,
      baum4a: null,
      baum5: null,
      baum6Transition: null,
      baum6: null,
      baum7: null,
      baum8: null,
      baum9: null,
      baum10: null,
      baumFinal: null
    },
    spriteParams: [
      { // Haus
        name: 'baum1',
        group: 'group1',
        anchor: 0.5,
        offsetX: 0,
        offsetY: 0,
        scale: 1,
      },
      { // Mine in Tür
        name: 'baum2',
        group: 'group1',
        offsetX: -5e+1,
        offsetY: -1.275e+2,
        scale: 3.97e-2,
      },
      { // Medaillon
        name: 'baum2a',
        group: 'group1',
        offsetX: 4.32e+1,
        offsetY: 5.555e+1,
        scale: 9.5e-4,
      },
      { // Flur
        name: 'baum3',
        group: 'group1',
        offsetX: 8.5e-1,
        offsetY: 1.06e+0  ,
        scale: 1.6e-4,
      },
      { // Türklinke
        name: 'baum3a',
        group: 'group1',
        offsetX: 1.7e-1,
        offsetY: 3.08e-1,
        scale: 5e-6,
      },
      { // Beerdigung
        name: 'baum4',
        group: 'group1',
        offsetX: 3e-3,
        offsetY: 1.04e-2,
        scale: 1.8e-7,
      },
      { // Chorknabe
        name: 'baum4a',
        group: 'group1',
        offsetX: 1.215e-4,
        offsetY: 1.116e-4,
        scale: 4.9e-9,
      },
      { // Schlund
        name: 'baum5',
        group: 'group1',
        offsetX: 5.15e-6,
        offsetY: 8.15e-6,
        scale: 1e-10,
      },
      { // Sanduhr
        name: 'baum6Transition',
        group: 'group1',
        anchor: 0.5,
        offsetX: 1.14e-7,
        offsetY: 6.08e-8,
        scale: 5e-13,
      },
      { // Sanduhr
        name: 'baum6',
        group: 'group2',
        anchor: 0.5,
        offsetX: 1, // setting this to not 0 helps with a bug during transition
        offsetY: 1, // setting this to not 0 helps with a bug during transition
        scale: 0,
      },
      { // Haus
        name: 'baum7',
        group: 'group2',
        offsetX: -6.7e+1,
        offsetY: -3.69e+2,
        scale: 0.08,
        portrait: {
          offsetX: -1.9e+2,
          offsetY: -1.01e+3,
          scale: 0.22          
        }
      },
      { // Baum im Fenster
        name: 'baum8',
        group: 'group2',
        offsetX: 9.275e+1,
        offsetY: 6.95e+1,
        scale: 9e-4,
        portrait: {
          offsetX: 2.55e+2,
          offsetY: 1.911e+2,
          scale: 2.55e-3
        } 
      },
      { // Baby im Baum
        name: 'baum9',
        group: 'group2',
        offsetX: 8.94e-1,
        offsetY: 1.1e+0,
        scale: 1.8e-5,
        rotate: 0.3, // add rotation
        portrait: {
          offsetX: 2.535e+0,
          offsetY: 3.1e+0,
          scale: 5.2e-5,
          rotate: 0.3
        }
      },
      { // Weltkugel
        name: 'baum10',
        group: 'group2',
        offsetX: 1.295e-2,
        offsetY: 3.05e-2,
        scale: 1.199e-7,
        portrait: {
          offsetX: 3.73e-2,
          offsetY: 8.81e-2,
          scale: 3.599e-7
        }
      },
      { // Final Image
        name: 'baumFinal',
        group: 'group2',
        anchor: 0.5,
        offsetX: 2.7e-5,
        offsetY: 8.2e-5,
        scale: 2.1e-8 * 4,
        rotate: -0.3,
        portrait: {
          offsetX: 8e-5,
          offsetY: 2.49e-4,
          scale: 5.2e-8 * 4,
        }
      }
    ]
  }
