import CloseButton from './buttons/CloseButton'

function Link({ href, children, target="_blank", className }) {
  return (
    <a 
      className={`underline underline-offset-2 text-primary text-base mt-2 inline-block ${className}`}
      href={href}
      target={target}
      onClick={async () => {
        try {
          const response = await fetch('https://baum.minemusik.de/clickEvents.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
              'name': 'clickCreditsLink',
              'context': href
            })
          });
        } catch (error) {
          console.error('Error:', error);
        }                           
      }}
    >
      {children}
    </a>
  )
}

export default function InfoDrawer({ isOpen, handleClose }) {
  return (
    <>
      {isOpen && ( // dark overlay
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-20" onClick={handleClose}></div>
      )}
      <div 
        className={`z-20 fixed top-0 right-0 w-[90vw] max-w-xl h-full transform ease-in-out duration-300 overflow-y-scroll ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <CloseButton className={'absolute top-3 left-3 sm:top-8 sm:left-8'} handleClose={handleClose} />
        <div className="py-24 px-12 w-full text-primary bg-secondary bg-noise min-h-[100vh]">
          <h1 className="text-3xl sm:text-4xl pb-10 mt-8 sm:mt-14 ">Spielregeln</h1>
          <section className="text-lg sm:text-xl">
            <p className="mb-2">
              Scrolle dich tief durch die illustrierte Welt meines neuen Albums BAUM 🌳.
            </p>
            <p className="mb-2">
              Hinter den fünf Flecken auf meinem Album Cover befindet sich je ein Level des Spiels.
            </p>
            <p className="mb-2">
              In jedem Level versteckt sich ein Bild und darin wiederum ein weiteres Bild usw., das du durch heranzoomen finden musst.
            </p>
            <p>
              Wenn du dich durch alle Bilder eines Levels gescrollt hast, hat sich im letzten Bild ein Zauberblatt versteckt. Wenn du alle 5 Blätter gesammelt hast, kannst du am Gewinnspiel teilnehmen. Viel Spaß beim Zoomen und Entdecken!
            </p>
          </section>
          <h1 className="text-3xl sm:text-4xl pt-14 pb-10">Credits</h1>
          <ul className="text-lg text-primary sm:text-xl">
            <li className="mb-10">
              <ul>
                <li className="mb-3">
                  <h2 className="text-xl font-bold mb-2">Yitong Feng</h2> 
                  <p className="tracking-tighter">Illustrationen für <i>"Weitergerannt"</i> und <i>"Ich Weiss Es Nicht"</i></p>
                  <Link className="" href="https://www.instagram.com/feng_yitong">www.instagram.com/feng_yitong</Link>
                </li>
              </ul>
            </li>
            <li className="mb-10">
              <ul>
                <li className="mb-3">
                  <h2 className="text-xl font-bold mb-2">Sophie Meyerhoff</h2> 
                  <p className="tracking-tighter">Illustrationen für <i>"Baum"</i>, <i>"Stoin"</i> und <i>"Nichts Ist Umsonst"</i></p>
                  <Link href="https://www.smyff.com">www.smyff.com</Link>
                  <span> / </span>
                  <Link href="https://www.instagram.com/smyff">www.instagram.com/smyff</Link>
                </li>
              </ul>
            </li>
            <li className="mb-10">
              <ul>
                <li className="mb-3">
                  <h2 className="text-xl font-bold mb-2">Moritz Pflüger</h2> 
                  <p className="tracking-tighter">Programmierung, UX & Webdesign</p>
                  <Link href="https://moritzpflueger.vercel.app/">moritzpflueger.vercel.app</Link>
                </li>
              </ul>
            </li>
            <li className="mb-10">
              <ul>
                <li className="mb-3">
                  <h2 className="text-xl font-bold mb-2">Mine</h2> 
                  <p className="text-primary">Idee</p>
                </li>
              </ul>
            </li>
            <li className="">
              <ul>
                <li className="mb-3">
                  <p className="text-primary mb-2">Special Thanks</p>
                  <h2 className="text-xl font-bold">Benjamin Bartosch</h2> 
                  <Link href="https://www.bartosch.xyz">www.bartosch.xyz</Link>
                </li>
              </ul>
            </li>
          </ul>

          <h1 className="text-3xl sm:text-4xl pt-16 pb-10">Impressum</h1>
          <address className="text-lg sm:text-xl not-italic">
            Jasmin Stocker<br />
            c/o Karakter Worldwide GmbH<br />
            Skalitzer Str. 67<br />
            10997 Berlin<br />
            <br />
            <Link className="text-xl" href="mailto:mine@karakter-worldwide.com">
              mine@karakter-worldwide.com
            </Link>
          </address>   
        </div>
      </div>    
    </>
  )
}