import { baumConfig } from './configs/baumConfig'
import { stoinConfig } from './configs/stoinConfig'
import { nichtsistumsonstConfig } from './configs/nichtsistumsonstConfig'
import { weitergeranntConfig } from './configs/weitergeranntConfig'
import { ichweissesnichtConfig } from './configs/ichweissesnichtConfig'

export const configs = [
  baumConfig,
  stoinConfig,
  nichtsistumsonstConfig,
  weitergeranntConfig,
  ichweissesnichtConfig
]
