import { debounce } from "lodash";
import Hammer from 'hammerjs';
import { useEffect, useRef } from "react";
import * as PIXI from 'pixi.js';
import Sparkles from "./SparklesEffect";
import { levelSprites } from '../configs/levelSpritesConfig'

export default function AlbumCover({ levelProgress, gameIsCompleted, handleLevelClick }) {

  const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

  const appRef = useRef(null);
  const pixiContainer = useRef(null);
  const scaleRef = useRef(0.5);
  const isDraggingRef = useRef(false);
  const isPanningRef = useRef(false);
  const dragStartRef = useRef({ x: 0, y: 0 });
  const containerRef = useRef(null);
  const coverImagePosRef = useRef({ x: 0, y: 0 });
  const tilingSpriteRef = useRef(null);
  const initialPinchScaleRef = useRef(scaleRef.current);
  const sparkleBaseDelay = useRef(0);
  const sparklesRef = useRef({
    baum: [],
    stoin: [],
    nichtsistumsonst: [],
    weitergerannt: [],
    ichweissesnicht: [],
  });
  const showSparklesRef = useRef({
    baum: false,
    stoin: false,
    nichtsistumsonst: false,
    weitergerannt: false,
    ichweissesnicht: false,
  });

  useEffect(() => {
    appRef.current = new PIXI.Application({ 
      background: '#2b4597', // default color 
      resizeTo: window,
      antialias: false, // set to false to increase mobile performance
      premultipliedAlpha: false, // set to false to increase mobile performance
      backgroundAlpha: 1, // set to 1 to increase mobile performance
      cullable: true // set to true to increase mobile performance
    });
    
    appRef.current.renderer.events.autoPreventDefault = false;
    appRef.current.renderer.view.style.touchAction = 'auto';
    
    const resize = () => {
      appRef.current.renderer.resize(window.innerWidth, window.innerHeight);
      containerRef.current.x = appRef.current.screen.width / 2;
      containerRef.current.y = appRef.current.screen.height;
      tilingSpriteRef.current.width = appRef.current.screen.width;
      tilingSpriteRef.current.height = appRef.current.screen.height;
    };    
    
    pixiContainer.current.appendChild(appRef.current.view);
    const debouncedHandleWheel = debounce(handleWheel, 7); // debounce as a precaution
    appRef.current.view.addEventListener('wheel', debouncedHandleWheel);
    window.addEventListener('resize', resize);
    PIXI.BaseTexture.defaultOptions.scaleMode = PIXI.SCALE_MODES.NEAREST;

    const container = new PIXI.Container();
    container.x = 8; // slight offset to center the image to center image visually
    containerRef.current = container;
    appRef.current.stage.addChild(container);

    let texture = PIXI.Texture.from('./assets/noise.png');
    tilingSpriteRef.current = new PIXI.TilingSprite(texture, appRef.current.screen.width, appRef.current.screen.height);
    tilingSpriteRef.current.scale.set(1.6);
    containerRef.current.addChild(tilingSpriteRef.current);
    // let texture2 = PIXI.Texture.from('./assets/noise.png');
    // appRef.current.stage.addChild(new PIXI.TilingSprite(texture2, appRef.current.screen.width, appRef.current.screen.height));
    
    const coverImage = PIXI.Sprite.from('./assets/cover/Album-Cover_Baum_1.webp');
    coverImage.name = 'coverImage';
    coverImage.anchor.set(0.5, 1); // anchor point is center bottom
    coverImage.x = appRef.current.screen.width / 2;
    coverImage.y = appRef.current.screen.height;
    containerRef.current.addChild(coverImage);
    containerRef.current = coverImage;
    if (coverImage.texture.baseTexture.valid) {
      initCoverImage();
    } else {
      coverImage.texture.baseTexture.on('loaded', initCoverImage);
    }

    function initCoverImage() {
      const coverImageBounds = coverImage.getBounds();
      const canvasBounds = appRef.current.screen;
      const scaleRatio = canvasBounds.width > canvasBounds.height ? 0.95 : 0.85;
      scaleRef.current = canvasBounds.height / coverImageBounds.height * scaleRatio;
      coverImage.scale.set(scaleRef.current);
      levelSprites.forEach(({ sprite, ...config }) => {
        sprite = PIXI.Sprite.from(`./assets/cover/${config.fileName}`);
        sprite.name = config.levelName;
        sprite.anchor.set(1, 1); // anchor point is right bottom
        sprite.x = config.x;
        sprite.y = config.y;
        sprite.scale.set(0.5);
        sprite.eventMode = 'static';
        sprite.cursor = 'pointer';
        sprite.on('pointertap', (event) => {
          if (!isPanningRef.current) handleLevelClick(event, config.levelName);
        });
        sprite.texture.baseTexture.on('loaded', () => {
          initSparkles(config);
        });  
        containerRef.current.addChild(sprite);    
      })
    }

    function initSparkles(config) {
      const sparklesCount = 10;

      for (let i = 0; i < sparklesCount; i++) {
        const sparkleSprite = PIXI.Sprite.from('./assets/sparkle.png');
        sparkleSprite.x = config.x + random(0, config.sparkles.maxWidth) + config.sparkles.offsetX;
        sparkleSprite.y = config.y + random(0, config.sparkles.maxHeight) + config.sparkles.offsetY;
        sparkleSprite.anchor.set(0.5);
        sparkleSprite.scale.set(0);
        sparkleSprite.animated = false;
        sparkleSprite.visible = false;
        sparkleSprite.animationProgress = 0; // Progress of the animation            
        sparkleSprite.elapsedTime = 0; // Progress of the animation
        sparkleBaseDelay.current += random(500, 3000);
        sparkleSprite.animationDelay = sparkleBaseDelay.current; // Delay in milliseconds
        sparklesRef.current[config.levelName].push(sparkleSprite);
        containerRef.current.addChild(sparkleSprite);            
      }
      sparkleBaseDelay.current = 0;

      appRef.current.ticker.add((deltaTime) => {
        if (gameIsCompleted) appRef.current.ticker.stop();
        levelSprites.forEach(({ levelName }) => {
          if (!showSparklesRef.current[levelName]) return;

          sparklesRef.current[levelName].forEach((sparkle, index) => {
            if (sparkle.animated) return;
            
            sparkle.elapsedTime += deltaTime * 1000/60;
            
            if (sparkle.elapsedTime > sparkle.animationDelay) {              
              sparkle.visible = true;
              sparkle.animationProgress += deltaTime * 0.02; // Control the speed of scaling
              const scale = Math.sin(sparkle.animationProgress);
              sparkle.scale.set(scale * 0.6);

              if (sparkle.animationProgress >= Math.PI) {
                sparkle.visible = false;
                sparkle.animated = true;
                sparkle.elapsedTime = 0;
                if (index === sparklesRef.current[levelName].length - 1) {
                  showSparklesRef.current[levelName] = false;
                }
              } 
            }        
          });              
        });
      });      
    }

    return () => {
      appRef.current.view.removeEventListener('wheel', debouncedHandleWheel);
      window.removeEventListener('resize', resize);
      appRef.current.destroy(true, true);
    };
  }, []);

  useEffect(() => {
    const manager = new Hammer.Manager(pixiContainer.current);
    manager.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
    manager.add(new Hammer.Pinch({ threshold: 0 })).recognizeWith(manager.get('pan'));
    
    manager.on('panstart', handlePanStart);
    manager.on('pan', handlePan);
    manager.on('panend', handlePanEnd);
    
    const debouncedHandlePinch = debounce(handlePinch, 7); // debounce to prevent side effects when transitioning between zoom levels
    manager.on('pinchstart', handlePinchStart);
    manager.on('pinch', debouncedHandlePinch);    
  }, []);  

  useEffect(() => {
    let sprite;
    levelSprites.forEach(({levelName}) => {
      if (levelProgress[levelName].completed) {
        sprite = containerRef.current.getChildByName(levelName);
        containerRef.current.removeChild(sprite);
        showSparklesRef.current[levelName] = true;
      }
    })
  }, [levelProgress]);

  function handlePanStart(e) {
    isDraggingRef.current = true;
    isPanningRef.current = true;
    coverImagePosRef.current = {
      x: containerRef.current.x,
      y: containerRef.current.y
    };
    dragStartRef.current.x = e.center.x;
    dragStartRef.current.y = e.center.y;
  }

  function handlePan(e) {
  if (isDraggingRef.current) {
    const dx = e.center.x - dragStartRef.current.x;
    const dy = e.center.y - dragStartRef.current.y;
    
    const imageBounds = containerRef.current.getBounds();
    const canvasBounds = appRef.current.screen;
    
    let newX = coverImagePosRef.current.x + dx;
    let newY = coverImagePosRef.current.y + dy;
    
    if (imageBounds.width < canvasBounds.width) {
      newX = canvasBounds.width / 2;
    } else {
      const maxX = 0 + imageBounds.width / 2;
      const minX = canvasBounds.width - imageBounds.width / 2;
      newX = Math.min(newX, maxX);
      newX = Math.max(newX, minX);
    } 
    
    const maxY = imageBounds.height;
    const minY = canvasBounds.bottom;
    newY = Math.min(newY, maxY);
    newY = Math.max(newY, minY);

    containerRef.current.x = newX;
    containerRef.current.y = newY;
  }
  }
  
  function handlePanEnd(e) {
    isDraggingRef.current = false;
    setTimeout(() => isPanningRef.current = false, 100);
    coverImagePosRef.current = {
      x: containerRef.current.x,
      y: containerRef.current.y
    };
  }

  function handlePinchStart(event) {
    initialPinchScaleRef.current = event.scale;
  }

  function handlePinch(event) {
    event.preventDefault();

    if (!containerRef.current) return;

    // Calculate zoom change directly from the pinch scale.
    const scaleChange = event.scale / initialPinchScaleRef.current;
    const newScale = scaleRef.current * scaleChange;

    const coverImageBounds = containerRef.current.getLocalBounds();
    const canvasBounds = appRef.current.screen;    

    // Calculate the minimum scale based on the aspect ratio
    const horizontalScale = canvasBounds.width / coverImageBounds.width;
    const minScale = horizontalScale / 1.1;

    if (newScale > 1) return;
    if (newScale < minScale) return;    

    // Determine the pinch center relative to the PIXI canvas.
    const boundingBox = appRef.current.view.getBoundingClientRect();
    const pinchCenter = {
        x: event.center.x - boundingBox.left,
        y: event.center.y - boundingBox.top
    };

    // Calculate the new position such that the zoom is centered around the pinch center.
    const px = (pinchCenter.x - containerRef.current.x) / scaleRef.current;
    const py = (pinchCenter.y - containerRef.current.y) / scaleRef.current;
    containerRef.current.scale.set(newScale);

    // Update the reference scale for further pinch moves
    initialPinchScaleRef.current = event.scale;
    
    containerRef.current.x = pinchCenter.x - px * newScale;
    containerRef.current.y = pinchCenter.y - py * newScale;

    // Update scale and position refs
    scaleRef.current = newScale;

    // Set the container's new scale
    containerRef.current.scale.set(scaleRef.current);
  };

  function handleWheel(event) {
    event.preventDefault();

    const scale = scaleRef.current;
    const normalizedDeltaY = Math.sign(event.deltaY); // -1 for zoom out, 1 for zoom in
    const zoomAmount = normalizedDeltaY > 0 ? 0.90 : 1.1; // 10% zoom

    // Calculate the new scale
    let newScale = scale * zoomAmount;

    const coverImageBounds = containerRef.current.getLocalBounds();
    const canvasBounds = appRef.current.screen;

    // Calculate the minimum scale based on the aspect ratio
    const horizontalScale = canvasBounds.width / coverImageBounds.width;
    const minScale = horizontalScale / 1.1;

    if (newScale > 1) return;
    if (newScale < minScale) return;
    
    // Determine where on the PIXI canvas the mouse is
    const boundingBox = event.target.getBoundingClientRect();
    const pointerPos = {
      x: event.clientX - boundingBox.left,
      y: event.clientY - boundingBox.top
    };
    
    // Calculate the new position such that the zoom is centered around the mouse
    const zoomFactor = newScale / scale;
    let newX = (1 - zoomFactor) * pointerPos.x + zoomFactor * containerRef.current.x;
    let newY = (1 - zoomFactor) * pointerPos.y + zoomFactor * containerRef.current.y;
    
    // Update the bounds after scaling
    containerRef.current.scale.set(newScale);
    tilingSpriteRef.current.scale.set(newScale * 3);
    
    const updatedBounds = containerRef.current.getLocalBounds();    
    
    const maxX = 0 + updatedBounds.width / 2 * newScale;
    const minX = canvasBounds.width - updatedBounds.width / 2 * newScale;
    newX = Math.min(newX, maxX);
    newX = Math.max(newX, minX);
    
    const maxY = updatedBounds.height * newScale;
    const minY = canvasBounds.bottom;
    newY = Math.min(newY, maxY);
    newY = Math.max(newY, minY);

    // Check to ensure we don't zoom out too far or too close
    if (newScale < minScale) {
      newX = canvasBounds.width / 2;
      newY = canvasBounds.height;
    };

    // Update scale and position refs
    scaleRef.current = newScale;
    coverImagePosRef.current = { x: newX, y: newY };

    containerRef.current.x = newX;
    containerRef.current.y = newY;      

    event.stopPropagation();
  };  

  return (
    <div className="flex flex-col w-full h-full">
      <div className='flex items-stretch flex-1 justify-center w-full'>
        <Sparkles showSparkles={gameIsCompleted}>
          <div ref={pixiContainer} />
        </Sparkles>
      </div>          
    </div>
  )
}