import SparkleIcon from "./icons/SparkleIcon";

export default function LevelProgressBar({ progress, gameIsCompleted, className, scale }) {

  const size = 42 * scale;
  const radius = 18 * scale;
  const strokeWidth = `${scale * 5}px`;
  const circumference = 2 * Math.PI * radius;
  const trackColor = '#555';
  const progressColor = '#f28402';
  const textSize = `${16 * scale}px`;


  return (
    <div className={`flex transition-all items-center justify-center relative text-neutral-500 ${className}`}>
      <label 
        className={`${progress !== 0 ? 'text-primary': ''} absolute font-bold`}
        style={{ fontSize: textSize }}
      >
        {`${ progress / 2 * 0.1 }/5`}
      </label>
      <svg  
        width={size} 
        height={size} 
        viewBox={`0 0 ${size} ${size}`} 
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{transform: 'rotate(-90deg)'}}
      >
        <defs>
          <linearGradient id="rainbowGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="gold" />
            <stop offset="100%" stopColor="deeppink" />
          </linearGradient>
        </defs>
        <circle 
          r={radius} 
          cx={size/2} 
          cy={size/2} 
          fill="transparent" 
          stroke={trackColor} 
          strokeWidth={strokeWidth} 
        />
        <circle 
          r={radius} 
          cx={size/2} 
          cy={size/2} 
          stroke={gameIsCompleted ? "url(#rainbowGradient)" : progressColor}
          strokeWidth={strokeWidth} 
          strokeLinecap="round"
          fill="transparent"
          strokeDasharray={`${circumference}px`}
          strokeDashoffset={`${circumference * ((100-progress)/100 )}px`}
          style={{ transition: 'stroke-dashoffset 1000ms ease-out' }}          
        />
      </svg>
    </div>           
  )
}