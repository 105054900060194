export default function LevelProgressMeterItem ({ isCompleted, isFadedOut, imageSrc, className }) {
  return (
    <div 
      // style={{ boxShadow: 'inset 0px 0px 17px 0px rgba(0,0,0,0.4)' }} 
      className={`${!isCompleted && !isFadedOut ? 'saturate-0 opacity-40' : ''} relative rounded-xl ${className}`}
    >
      <img 
        src={imageSrc} 
        alt="level icon 1 colored version" 
        className={`${isCompleted && !isFadedOut ? 'color-fill-animation' : ''} w-7 sm:w-8 h-7 sm:h-8`} 
      />
      { !isFadedOut && (
        <img 
          src={imageSrc} 
          alt="level icon 1 gray version" 
          className={`${isCompleted && !isFadedOut ? 'color-fill-animation-reversed' : ''} absolute top-0 left-0 right-0 bottom-0 m-auto saturate-0 brightness-150 w-7 sm:w-8 h-7 sm:h-8`}
        />        
      )

      }
    </div>
  )
}
