export default function InfoDrawerButton({ isVisible, handleClickInfoIcon }) {
  
  if (!isVisible) return null;

  return (
    <button 
      className="z-10 flex justify-center items-center shadow-xl hover:scale-110 origin-top-right sm:origin-center w-12 h-12 sm:w-12 sm:h-12 rounded-full absolute right-4 sm:right-5 top-4 sm:top-5 bg-slate-100 bg-noise text-black"
      onClick={handleClickInfoIcon}
    >
      <svg  className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.4} stroke="currentColor">
        <line x1="2" y1="9" x2="22" y2="9" strokeLinecap="round" strokeLinejoin="round" />
        <line x1="10" y1="15" x2="22" y2="15" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>  
  )  
}