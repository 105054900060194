import { useState, useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';
import Hammer, { DIRECTION_ALL } from 'hammerjs';
import GuiControls from './components/GuiControls';
import InfoDrawerButton from './components/InfoDrawerButton';
import HomeButton from './components/buttons/HomeButton';
import LevelProgressMeter from './components/LevelProgressMeter';
import MusicPlayer from './components/MusicPlayer';
import PixiContainer from './components/PixiContainer';
import AlbumCover from './components/AlbumCover';
import InfoDrawer from './components/InfoDrawer'
import { debounce } from 'lodash';
import { configs } from './config';
import SuccessModal from './components/SuccessModal';
import StartModal from './components/StartModal';

// Initialize the assets manifest file
const assetSize = 2096;
PIXI.Assets.init({ manifest: `./assetManifest_${assetSize}.json` });
// Scale mode for all textures, will retain pixelation
PIXI.BaseTexture.defaultOptions.scaleMode = PIXI.SCALE_MODES.NEAREST;

if ('mediaSession' in navigator) {
  navigator.mediaSession.metadata = new MediaMetadata({
    title: 'Nichts Ist Umsonst',
    artist: 'Mine',
    album: 'Baum'
  });
}

const App = () => {
  const isDevMode = process.env.NODE_ENV !== 'production';

  const [isCursorHidden, setIsCursorHidden] = useState(false);
  const [isUiVisible, setIsUiVisible] = useState(true);
  const mousePosition = useRef({ x: 0, y: 0 });

  const currentLevel = useRef(null);
  const [loadingProgress, setLoadingProgress] = useState(0); // 0 to 100
  const [showLoadingBar, setShowLoadingBar] = useState(false);
  const [levelProgress, setLevelProgress] = useState({ 
    baum: { completed: false, fadedOut: false },
    nichtsistumsonst: { completed: false, fadedOut: false },
    weitergerannt: { completed: false, fadedOut: false },
    stoin: { completed: false, fadedOut: false },
    ichweissesnicht: { completed: false, fadedOut: false } 
  });

  let timeoutId;
  let timeoutId2;
  const [zoomEffectIsActive, setZoomEffectIsActive] = useState(false);
  const [zoomEffectIsActive2, setZoomEffectIsActive2] = useState(false);
  const [transformOrigin, setTransformOrigin] = useState('center');
  
  const [showCover, setShowCover] = useState(true);
  const [showInfoDrawer, setShowInfoDrawer] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalWasDisplayed, setSuccessModalWasDisplayed] = useState(false);
  const [showStartModal, setShowStartModal] = useState(true);
  
  const aspectRatio = window.innerWidth / window.innerHeight; // Get aspect ratio
  const isLandscape = aspectRatio > 1; // Check if landscape
  
  const scaleRef = useRef(null); // Reference to the current scale
  const baseScale = useRef(0.1); // Set starting scales for portrait and landscape
  const nextLevelThreshold = useRef(100); // Threshold between zoom level 1 and 2
  scaleRef.current = baseScale.current; // Set the initial scale
  
  const [zoomLevelHelperFlag, setZoomLevelHelperFlag] = useState(false); // Helper flag to prevent side effects when transitioning between zoom levels
  const isDraggingRef = useRef(false); 
  const dragStartRef = useRef({ x: 0, y: 0 });
  const initialPinchScaleRef = useRef(1); // Reference to the initial pinch scale
  const maxScaleLimitRef = useRef({ scale: 100, zoomLevel: 1 }); // Maximum scale
  let minimalScaleLimit = 0.05; // Minimum scale
  const [zoomLevel, setZoomLevel] = useState(1); // start at zoom level 1
  const zoomLevelRef = useRef(null);
  zoomLevelRef.current = zoomLevel;
  
  const appRef = useRef(null);
  const containerRef = useRef(null);
  const containerPosRef = useRef({ x: 0, y: 0 });
  const containerRefs = useRef({
    level1: null,
    level2: null,
    // ... more if needed
  });
  
  const [spriteParams, setSpriteParams] = useState(null);
  const spritesRef = useRef(null);
  const [spriteKeys, setSpriteKeys] = useState(null)

  // final sprite animation
  const [finalSpriteScale, setFinalSpriteScale] = useState({ x: 1, y: 1 }); // Initial scale of the sprite
  const finaleSpriteAnimationFrameRef = useRef();
  const finalSpriteRef = useRef();
  
  
  useEffect(() => {
    // increment page visits by 1
    fetch('https://baum.minemusik.de/pageVisits.php')
      .catch(error => console.error(error));
  }, []);  
  
  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.key === 'h') setIsCursorHidden(prev => !prev);
      if (event.key === 'f') setIsUiVisible(prev => !prev);
      if (event.key === 'ArrowUp') handleKeyZoom(event, -1, 0.05);
      if (event.key === 'ArrowDown') handleKeyZoom(event, 1, 0.05);
    };
    
    const handleMouseMove = (event) => {
      mousePosition.current = {
        x: event.clientX,
        y: event.clientY
      }
    };
    
    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('mousemove', handleMouseMove);
    
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);  

  useEffect(() => {
    appRef.current = new PIXI.Application({ 
      background: '#ffffff', // default color 
      resizeTo: window,
      antialias: false, // set to false to increase mobile performance
      premultipliedAlpha: false, // set to false to increase mobile performance
      backgroundAlpha: 1, // set to 1 to increase mobile performance
      cullable: true // set to true to increase mobile performance
    });
    appRef.current.renderer.events.autoPreventDefault = false;
    appRef.current.renderer.view.style.touchAction = 'auto';
    const resize = () => {
      if (isDevMode) console.log('window is resizing');
      appRef.current.renderer.resize(window.innerWidth, window.innerHeight);
    };    
    const pixiContainer = document.getElementById('pixiContainer');
    pixiContainer.appendChild(appRef.current.view);
    const app = appRef.current; // TODO: is this necessary?
    PIXI.BaseTexture.defaultOptions.scaleMode = PIXI.SCALE_MODES.NEAREST;
    
    appRef.current.view.addEventListener('wheel', debouncedHandleWheel);
    window.addEventListener('resize', resize);
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    // Create the main container
    const container = new PIXI.Container();
    container.width = appRef.current.screen.width;
    container.height = appRef.current.screen.height;
    container.x = appRef.current.screen.width / 2;
    container.y = appRef.current.screen.height / 2;
    container.scale.set(scaleRef.current);
    app.stage.addChild(container);
    containerRef.current = container;

    // Container for zoom level group1
    const container1 = new PIXI.Container();
    container1.name = 'container1'
    containerRef.current.addChild(container1);
    container1.alpha = 1; // Initially visible
    containerRefs.current.level1 = container1;
    
    // Container for zoom level group2
    const container2 = new PIXI.Container();
    container2.name = 'container2'
    containerRef.current.addChild(container2);
    container2.alpha = 0; // Initially not visible
    containerRefs.current.level2 = container2;        

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timeoutId2);
      appRef.current.view.removeEventListener('wheel', debouncedHandleWheel);
      window.removeEventListener('resize', resize);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      app.destroy(true, true);
    };
  }, []);

  async function startLevel(levelName) {

    currentLevel.current = levelName;
    
    setShowCover(false);
    setZoomEffectIsActive(false);
    setZoomEffectIsActive2(false);

    const currentConfig = configs.find(config => config.name === levelName);
    if (!currentConfig) return;

    appRef.current.renderer.background.color = currentConfig.appBackground || '#ffffff' ; // Change background color

    baseScale.current = isLandscape ? currentConfig.baseScale.landscape : currentConfig.baseScale.portrait;
    containerRef.current.scale.set(baseScale.current);
    nextLevelThreshold.current = currentConfig.nextLevelThreshold;
    setZoomLevel(1); // Reset zoom level
    maxScaleLimitRef.current.scale = currentConfig.maxScaleLimit.scale;
    maxScaleLimitRef.current.zoomLevel = currentConfig.maxScaleLimit.zoomLevel;
    setSpriteKeys(currentConfig.spriteKeys);
    spritesRef.current = currentConfig.spritesRef;
    setSpriteParams(currentConfig.spriteParams);
    
    if (!currentConfig.spriteParams) return;
    loadAssets(levelName, currentConfig);

    try {
      const response = await fetch('https://baum.minemusik.de/clickEvents.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          'name': 'clickLevelStart',
          'context': levelName
        })
      });
    } catch (error) {
      console.error('Error:', error);
    }       
  };

  // Function to use the loaded assets and set their properties
  async function loadAssets(levelName, config) {

    let assetsForBundle = {};
    config.spriteParams.forEach((sprite) => {
      assetsForBundle[sprite.name] = sprite.path;
    });

    // Add the bundle using the assets object we just created
    try {
      // Load the bundle for the level and then use the assets
      setShowLoadingBar(true);
      const loadedAssets = await PIXI.Assets.loadBundle(levelName, (progress) => {
        let currentProgress = progress < 0.94 ? Math.floor(progress * 100) : 99;
        setLoadingProgress(currentProgress);
      });
      setTimeout(() => {
        setLoadingProgress(0);
        setShowLoadingBar(false);        
      }, 500);
        
      // Manipulate the assets after loading
      for (const spriteKey of config.spriteKeys) {
        const asset = loadedAssets[spriteKey];
        if (asset) {
          // Assuming asset is a texture, create a sprite
          const sprite = new PIXI.Sprite(asset);

          const params = config.spriteParams.find(sprite => {
            return sprite.name === spriteKey
          });
          if (!params) return;

          // There is a bug in potrait positioning of sprite7, so we use the different values for portrait
          if (!isLandscape && params.portrait) {
            params.offsetX = params.portrait.offsetX;
            params.offsetY = params.portrait.offsetY;
            params.scale = params.portrait.scale;
          }             
          sprite.scale.set(params.scale);
          if (params.anchor) sprite.anchor.set(params.anchor);
          if (params.zIndex) sprite.zIndex = params.zIndex;
          if (params.rotate) sprite.rotation = params.rotate;
          if (params.flipX) sprite.scale.x *= -1;

          if (params.link) {
            sprite.eventMode = 'static';
            sprite.cursor = 'pointer';
            sprite.on('pointertap', async (event) => {
              window.open(params.link, '_blank');
              try {
                const response = await fetch('https://baum.minemusik.de/clickEvents.php', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  },
                  body: new URLSearchParams({
                    'name': 'clickSpriteLink',
                    'context': params.link
                  })
                });
              } catch (error) {
                console.error('Error:', error);
              }              
            });
            // make sure the sprite is not covered by other sprites            
            if (params.group === 'group1') containerRefs.current.level1.sortableChildren = true;
            if (params.group === 'group2') containerRefs.current.level2.sortableChildren = true;
            sprite.zIndex = 100;
          }

          if(
            ['baumFinal', 'stoinFinal', 'ichweissesnichtFinal', 'weitergeranntFinal', 'nichtsistumsonstFinal'].includes(params.name) 
            // || ['baum1', 'stoin1', 'ichweissesnicht1', 'weitergerannt1', 'nichtsistumsonst1'].includes(params.name)
          ) {
            // make the sprite interactive
            sprite.eventMode = 'static';
            sprite.cursor = 'pointer';
            finalSpriteRef.current = sprite;
            sprite.on('pointertap', async (event) => {
              // Get mouse position relative to the viewport
              const x = Math.floor(event.clientX); // x position within the viewport.
              const y = Math.floor(event.clientY); // y position within the viewport.
              
              // Update transform origin
              setTransformOrigin(`${x}px ${y}px`);   
              handleLevelProgress(currentLevel.current, 'completed');        
              setZoomEffectIsActive2(true);
              startFinalSpriteAnimation(sprite);

              timeoutId = setTimeout(() => {
                handleLevelProgress(currentLevel.current, 'fadedOut');
              }, 7000)
              timeoutId2 = setTimeout(() => {
                toggleCover()
              }, 1000);
              try {
                const response = await fetch('https://baum.minemusik.de/clickEvents.php', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  },
                  body: new URLSearchParams({
                    'name': 'clickLevelEnd',
                    'context': currentLevel.current
                  })
                });
              } catch (error) {
                console.error('Error:', error);
              }                     
            });
            // // Ticker callback function
            // // When adding the ticker callback
            // const tickerCallback = () => bounceSprite(sprite);
            // appRef.current.ticker.add(tickerCallback);
            // finalSpriteTickerCallbackRef.current[levelName] = tickerCallback;
          }          

          if (
            params.name === 'nichtsistumsonst4' ||
            params.name === 'nichtsistumsonst4a'
          ) {
            sprite.visible = false; // hide for TV flicker animation
          }

          // Set the sprite position based on the previous sprite
          const previousSpriteKey = config.spriteKeys[config.spriteKeys.indexOf(spriteKey) - 1]
          const previousSprite = spritesRef.current[previousSpriteKey];
          if (previousSprite) {
            sprite.x = previousSprite.x + params.offsetX;
            sprite.y = previousSprite.y + params.offsetY;
          }

          // Add the sprite to spritesRef
          spritesRef.current[spriteKey] = sprite;

          // Add the sprite to the correct container
          if (params.group === 'group1') containerRefs.current.level1.addChild(sprite);
          if (params.group === 'group2') containerRefs.current.level2.addChild(sprite);
          
        }
      }
    } catch (error) {
      console.error('Error using assets:', error);
    }
  };  

  useEffect(() => {
    containerRef.current.eventMode = 'static';
    
    var hammertime = new Hammer(appRef.current.view);
    hammertime.get('pan').set({ direction: DIRECTION_ALL });
    hammertime.get('pinch').set({ enable: true });
    hammertime.get('doubletap');
    
    hammertime.on('panstart', handlePanStart);
    hammertime.on('pan', handlePan);
    hammertime.on('panend', handlePanEnd);
    
    hammertime.on('pinchstart', handlePinchStart);
    hammertime.on('pinch', debouncedHandlePinch);
    hammertime.on('pinchend', handlePinchEnd);
    hammertime.on('doubletap', handleDoubleTap);
    
    return () => {
      hammertime.off('panstart', handlePanStart);
      hammertime.off('pan', handlePan);
      hammertime.off('panend', handlePanEnd);
      
      hammertime.off('pinchstart', handlePinchStart);
      hammertime.off('pinch', debouncedHandlePinch);      
      hammertime.off('doubletap', handleDoubleTap);
    };    
  }, []);

  useEffect(() => {
    // Reset to the initial position of the container
    if (showCover === true) {
      containerRef.current.scale.set(baseScale.current);
      containerRef.current.x = appRef.current.screen.width / 2;
      containerRef.current.y = appRef.current.screen.height / 2;
    }
  }, [showCover])

  useEffect(() => {
    zoomLevelRef.current = zoomLevel;
    setZoomLevelHelperFlag(false);
  }, [zoomLevel]);

  function handlePanStart(e) {
    isDraggingRef.current = true;
    containerPosRef.current = {
      x: containerRef.current.x,
      y: containerRef.current.y
    };
    dragStartRef.current.x = e.center.x;
    dragStartRef.current.y = e.center.y;
  }

  function handlePan(e) {
    if (isDraggingRef.current) {
      // Use only the first touch point for calculating deltas
      const firstTouch = e.pointers[0];
      const dx = firstTouch.clientX - dragStartRef.current.x;
      const dy = firstTouch.clientY - dragStartRef.current.y;
      containerRef.current.x = containerPosRef.current.x + dx;
      containerRef.current.y = containerPosRef.current.y + dy;
    }
  }
  
  function handlePanEnd(e) {
    isDraggingRef.current = false;
    containerPosRef.current = {
      x: containerRef.current.x,
      y: containerRef.current.y
    };
  }

  function handlePinchStart(e) {
    initialPinchScaleRef.current = e.scale;
    isDraggingRef.current = true;
    containerPosRef.current = {
      x: containerRef.current.x,
      y: containerRef.current.y
    };
    const boundingBox = appRef.current.view.getBoundingClientRect();
    const pinchCenter = {
        x: e.center.x - boundingBox.left,
        y: e.center.y - boundingBox.top
    };    
    dragStartRef.current.x = pinchCenter.x;
    dragStartRef.current.y = pinchCenter.y;    
  }

  function handlePinchEnd(e) {
    isDraggingRef.current = false;
    containerPosRef.current = {
      x: containerRef.current.x,
      y: containerRef.current.y
    };
  }

  const debouncedHandlePinch = debounce(handlePinch, 7); // debounce to prevent side effects when transitioning between zoom levels
  const debouncedHandleWheel = debounce(handleWheel, 7); // debounce as a precaution

  function handlePinch(e) {
    e.preventDefault();

    if (!containerRef.current) return;

    if (isDevMode) console.log('handlepinch, scale', scaleRef.current);

    // Calculate zoom change directly from the pinch scale.
    const scaleChange = e.scale / initialPinchScaleRef.current;
    const zoomFactor = scaleRef.current * scaleChange;

    // Calculate the new scale.
    const newScale = Math.max(minimalScaleLimit, zoomFactor);
    if (newScale > maxScaleLimitRef.current.scale && zoomLevelRef.current === maxScaleLimitRef.current.zoomLevel) return;

    // Determine the pinch center relative to the PIXI canvas.
    const boundingBox = appRef.current.view.getBoundingClientRect();
    const pinchCenter = {
        x: e.center.x - boundingBox.left,
        y: e.center.y - boundingBox.top
    };

    // Calculate the new position such that the zoom is centered around the pinch center.
    const px = (pinchCenter.x - containerRef.current.x) / scaleRef.current;
    const py = (pinchCenter.y - containerRef.current.y) / scaleRef.current;
    const dx = pinchCenter.x - dragStartRef.current.x;
    const dy = pinchCenter.y - dragStartRef.current.y;
    containerRef.current.scale.set(newScale);

    // Update the reference scale for further pinch moves
    initialPinchScaleRef.current = e.scale;

    if (scaleRef.current < baseScale.current) {
      containerRef.current.x = appRef.current.screen.width / 2;
      containerRef.current.y = appRef.current.screen.height / 2;
    } else {    
      containerRef.current.x = pinchCenter.x - px * newScale + dx;
      containerRef.current.y = pinchCenter.y - py * newScale + dy;
    }

    // Update scale and position refs
    scaleRef.current = newScale;
    
    containerPosRef.current = {
        x: containerRef.current.x,
        y: containerRef.current.y
    };

    // Set the container's new scale
    containerRef.current.scale.set(scaleRef.current);

    // Update dragStartRef to the current pinch center
    dragStartRef.current.x = pinchCenter.x;
    dragStartRef.current.y = pinchCenter.y;

    // handlePanStart(e)
    // handlePan(e)

    handleZoomlevelChange(newScale);
  };

  function handleKeyZoom(event, direction, speed) {
    
    if (isDevMode) console.log('handleKeyZoom', scaleRef.current);

    event.preventDefault();

    const scale = scaleRef.current;
    const normalizedDeltaY = direction; // -1 for zoom out, 1 for zoom in
    const zoomAmount = normalizedDeltaY > 0 ? 1 - speed : 1 + speed; // 1% zoom

    const newScale = scale * zoomAmount;

    // Check to ensure we don't zoom out too far
    if (newScale < minimalScaleLimit) return;
    if (newScale > maxScaleLimitRef.current.scale && zoomLevelRef.current === maxScaleLimitRef.current.zoomLevel) return;

    // const pointerPos = spritesRef.current.nichtsistumsonst8.toGlobal(new PIXI.Point(0, 0));
    const pointerPos = mousePosition.current;

    // Calculate the new position such that the zoom is centered around the mouse
    const zoomFactor = newScale / scale;
    containerRef.current.x = (1 - zoomFactor) * pointerPos.x + zoomFactor * containerRef.current.x;
    containerRef.current.y = (1 - zoomFactor) * pointerPos.y + zoomFactor * containerRef.current.y;

    // Update scale and position refs
    scaleRef.current = newScale;
    
    containerPosRef.current = {
      x: containerRef.current.x,
      y: containerRef.current.y
    };

    // Set the container's new scale
    containerRef.current.scale.set(scaleRef.current);

    event.stopPropagation();

    handleZoomlevelChange(newScale);
  };  

  function handleWheel(event) {
    
    if (isDevMode) console.log('scale', scaleRef.current);

    event.preventDefault();

    const scale = scaleRef.current;
    const normalizedDeltaY = Math.sign(event.deltaY); // -1 for zoom out, 1 for zoom in
    const zoomAmount = normalizedDeltaY > 0 ? 0.90 : 1.1; // 10% zoom

    // Calculate the new scale
    const newScale = scale * zoomAmount;

    // Check to ensure we don't zoom out too far
    if (newScale < minimalScaleLimit) return;
    if (newScale > maxScaleLimitRef.current.scale && zoomLevelRef.current === maxScaleLimitRef.current.zoomLevel) return;

    // Determine where on the PIXI canvas the mouse is
    const boundingBox = event.target.getBoundingClientRect();
    const pointerPos = {
      x: event.clientX - boundingBox.left,
      y: event.clientY - boundingBox.top
    };

    // Calculate the new position such that the zoom is centered around the mouse
    const zoomFactor = newScale / scale;

    if (scaleRef.current < baseScale.current) {
      containerRef.current.x = appRef.current.screen.width / 2;
      containerRef.current.y = appRef.current.screen.height / 2;
    } else {
      containerRef.current.x = (1 - zoomFactor) * pointerPos.x + zoomFactor * containerRef.current.x;
      containerRef.current.y = (1 - zoomFactor) * pointerPos.y + zoomFactor * containerRef.current.y;
    }

    // Update scale and position refs
    scaleRef.current = newScale;
    
    containerPosRef.current = {
      x: containerRef.current.x,
      y: containerRef.current.y
    };

    // Set the container's new scale
    containerRef.current.scale.set(scaleRef.current);

    event.stopPropagation();

    handleZoomlevelChange(newScale);
  };

  function startFlickering(sprite1, sprite2) {
    let flickerCount = 0;
    const maxFlicker = 10; // Set the desired number of flicker cycles
    const flickerInterval = 50; // Set the desired speed of flickering

    const flicker = () => {
      if (flickerCount < maxFlicker) {
        // Alternate between 0 and 1 alpha value on each flicker
        sprite1.alpha = sprite1.alpha === 1 ? 0 : 1;
        sprite2.alpha = sprite2.alpha === 1 ? 0 : 1;
        setTimeout(flicker, flickerInterval); // Continue flickering at set intervals
        flickerCount++;
      } else {
        sprite1.alpha = 1; // Ensure sprite is fully visible after flickering
        sprite2.alpha = 1; // Ensure sprite is fully visible after flickering
      }
    };

    flicker();
  }

  function handleZoomlevelChange (newScale) {
    // Update zoom level based on the new scale
    let newZoomLevel = calculateZoomLevel(scaleRef.current);

    if (
      currentLevel.current === 'nichtsistumsonst' && 
      !spritesRef.current.nichtsistumsonst4.visible &&
      newScale > 0.5
    ) {
      spritesRef.current.nichtsistumsonst4.visible = true;
      spritesRef.current.nichtsistumsonst4a.visible = true;
      startFlickering(spritesRef.current.nichtsistumsonst4, spritesRef.current.nichtsistumsonst4a); // Start flickering effect      
    }

    // If the zoom level has changed
    if (newZoomLevel !== zoomLevelRef.current && !zoomLevelHelperFlag) {
      if (isDevMode) console.log('NEW ZOOM LEVEL REACHED')
      setZoomLevelHelperFlag(true);
      
      containerRefs.current[`level${zoomLevelRef.current}`].alpha = 0; // Deactivate old container
      containerRefs.current[`level${newZoomLevel}`].alpha = 1; // Activate new container
      
      if (newZoomLevel === 2) {
        if (isDevMode) console.log('new zoom level 2');

        let transitionSprite = null;
        
        if (currentLevel.current === 'nichtsistumsonst') {
          transitionSprite = spritesRef.current.nichtsistumsonst10Transition;
          spritesRef.current.nichtsistumsonst10.scale.x = transitionSprite.scale.x * nextLevelThreshold.current / baseScale.current;
          spritesRef.current.nichtsistumsonst10.scale.y = transitionSprite.scale.y * nextLevelThreshold.current / baseScale.current;
        }
        if (currentLevel.current === 'baum') {
          transitionSprite = spritesRef.current.baum6Transition;
          spritesRef.current.baum6.scale.x = transitionSprite.scale.x * nextLevelThreshold.current / baseScale.current;
          spritesRef.current.baum6.scale.y = transitionSprite.scale.y * nextLevelThreshold.current / baseScale.current;
        }
        if (!transitionSprite) return;

        const globalPos = transitionSprite.toGlobal(new PIXI.Point(0, 0));  
        
        containerRef.current.x = globalPos.x;
        containerRef.current.y = globalPos.y;

        scaleRef.current = baseScale.current;
        containerRef.current.scale.set(baseScale.current);          
      } else if (newZoomLevel === 1 && scaleRef.current < 1) {
        if (isDevMode) console.log('new zoom level 1 - (1)');
        
        containerRefs.current.level1.alpha = 0;
        setTimeout(() => { // TODO: get rid of timeout
          let transitionSprite = null;

          if (currentLevel.current === 'nichtsistumsonst') {
            transitionSprite = spritesRef.current.nichtsistumsonst10;
          }
          if (currentLevel.current === 'baum') {
            transitionSprite = spritesRef.current.baum6;
          }

          const globalPos = transitionSprite.toGlobal(new PIXI.Point(0, 0));

          // Calculate the screen center
          const screenCenterX = appRef.current.screen.width / 2;
          const screenCenterY = appRef.current.screen.height / 2;

          // Calculate the offset of the sprite from the screen center
          const offsetX =  screenCenterX - globalPos.x;
          const offsetY =  screenCenterY - globalPos.y;
          
          // Set the stage position to the center of the screen
          if (currentLevel.current === 'nichtsistumsonst') {
            containerRef.current.x = screenCenterX - spritesRef.current.nichtsistumsonst10Transition.x * nextLevelThreshold.current - offsetX;
            containerRef.current.y = screenCenterY - spritesRef.current.nichtsistumsonst10Transition.y * nextLevelThreshold.current - offsetY;             
          }
          if (currentLevel.current === 'baum') {
            containerRef.current.x = screenCenterX - spritesRef.current.baum6Transition.x * nextLevelThreshold.current - offsetX;
            containerRef.current.y = screenCenterY - spritesRef.current.baum6Transition.y * nextLevelThreshold.current - offsetY;             
          }
          
          scaleRef.current = nextLevelThreshold.current;
          containerRef.current.scale.set(nextLevelThreshold.current); 

          containerRefs.current.level1.alpha = 1;
        }, 50);        
      }
      
      // Update zoom level state
      setZoomLevel(newZoomLevel);
    }    
  };


  function calculateZoomLevel(scale) {
    if (zoomLevelRef.current === 1) {
      return scale < nextLevelThreshold.current ? 1 : 2;
    } 
    if (zoomLevelRef.current === 2) {
      return scale < baseScale.current ? 1 : 2;
    }
    return 1; // default case
  }

  const ZOOM_IN_AMOUNT = 6;  // Modify this value to adjust the zoom in strength.
  const ZOOM_IN_DURATION = 250;  // in milliseconds

  function handleDoubleTap(e) {
    const startPosition = { x: containerRef.current.x, y: containerRef.current.y };
    const startScale = scaleRef.current;

    // Calculate target scale and position.
    const newScale = startScale * ZOOM_IN_AMOUNT;
    if (newScale > maxScaleLimitRef.current.scale && zoomLevelRef.current === maxScaleLimitRef.current.zoomLevel) return;
    
    const boundingBox = appRef.current.view.getBoundingClientRect();
    const tapPos = {
      x: e.center.x - boundingBox.left,
      y: e.center.y - boundingBox.top
    };

    const targetPosition = {
      x: (1 - ZOOM_IN_AMOUNT) * tapPos.x + ZOOM_IN_AMOUNT * containerRef.current.x,
      y: (1 - ZOOM_IN_AMOUNT) * tapPos.y + ZOOM_IN_AMOUNT * containerRef.current.y
    };

    let startTime;

    function animateZoom(currentTime) {
      if (!startTime) startTime = currentTime;
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / ZOOM_IN_DURATION, 1);

      // Linear interpolation between start and target values.
      const currentScale = startScale + (newScale - startScale) * progress;
      const currentX = startPosition.x + (targetPosition.x - startPosition.x) * progress;
      const currentY = startPosition.y + (targetPosition.y - startPosition.y) * progress;

      // Exit animation loop if we hit zoom level change:
      // Calculate the current zoom level
      const currentZoomLevel = calculateZoomLevel(currentScale);

      if (currentZoomLevel !== zoomLevelRef.current) {
        // Zoom level changed. Update immediately without animation.

        containerRef.current.scale.set(newScale); // Set the target scale directly
        containerRef.current.x = targetPosition.x; // Set the target position directly
        containerRef.current.y = targetPosition.y; // Set the target position directly

        scaleRef.current = newScale;
        containerPosRef.current = {
          x: containerRef.current.x,
          y: containerRef.current.y
        };

        handleZoomlevelChange(newScale); // Handle the zoom level change

        return; // Exit the animation loop
      }
      
      handleZoomlevelChange(newScale); // Handle the zoom level change
      
      containerRef.current.scale.set(currentScale);
      containerRef.current.x = currentX;
      containerRef.current.y = currentY;

      scaleRef.current = currentScale;
      containerPosRef.current = {
        x: containerRef.current.x,
        y: containerRef.current.y
      };

      if (progress < 1) {
        requestAnimationFrame(animateZoom);
      }
    }

    requestAnimationFrame(animateZoom);
  }

  function handleLevelClick(event, newLevel) {
    // Get mouse position relative to the viewport
    const x = event.clientX; // x position within the viewport.
    const y = event.clientY; // y position within the viewport.
    
    // Update transform origin
    setTransformOrigin(`${x}px ${y}px`);                  
    setZoomEffectIsActive(true)
    setTimeout(() => {
      startLevel(newLevel)
    }, 1000)
  }

  const startFinalSpriteAnimation = (sprite) => {
    const startTime = Date.now();
    let scaleFactor = 5;
    const startScale = { x: sprite.scale.x, y: sprite.scale.y };
    const endScale = { x: sprite.scale.x * scaleFactor, y: sprite.scale.y * scaleFactor };

    const animate = () => {

      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / 1000, 1);

      // Interpolate scale
      const newScaleX = startScale.x + (endScale.x - startScale.x) * progress;
      const newScaleY = startScale.y + (endScale.y - startScale.y) * progress;
      
      if (finalSpriteRef.current) { // Check if the sprite reference is not null
        finalSpriteRef.current.scale.x = newScaleX;
        finalSpriteRef.current.scale.y = newScaleY;
        if (progress < 1) {
          finaleSpriteAnimationFrameRef.current = requestAnimationFrame(animate);
        }
      }
    };

    animate();
  };    

  const stopFinalSpriteAnimation = () => {
    if (finaleSpriteAnimationFrameRef.current) {
      cancelAnimationFrame(finaleSpriteAnimationFrameRef.current);
      finaleSpriteAnimationFrameRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      if (finaleSpriteAnimationFrameRef.current) {
        cancelAnimationFrame(finaleSpriteAnimationFrameRef.current);
      }
    };
  }, []);  

  // Leave easter egg in console
  useEffect(() => {
    console.log('🍂 🔍')
  }, [])  

  useEffect(() => {
    if (finalSpriteRef.current) {
      finalSpriteRef.current.scale.x = finalSpriteScale.x;
      finalSpriteRef.current.scale.y = finalSpriteScale.y;
    }
  }, [finalSpriteScale]);

  function removeSprites () {

    // final sprite is animated, so we need to stop the animation before removing the sprite
    stopFinalSpriteAnimation();

    // level 1
    containerRefs.current.level1.children.forEach(sprite => {
      sprite.destroy({texture: true, baseTexture: true});
    })
    containerRefs.current.level1?.removeChildren()
    // level 2
    containerRefs.current.level2.children.forEach(sprite => {
      sprite.destroy({texture: true, baseTexture: true});
    })
    containerRefs.current.level2?.removeChildren()        
  };

  function toggleCover () {
    removeSprites();
    containerRefs.current.level2.alpha = 0;    
    containerRefs.current.level1.alpha = 1;    
    setShowCover(true);
  }

  const handleBeforeUnload = (e) => {
    if (!isDevMode) {
      e.preventDefault();
      e.returnValue = ''; // Chrome requires returnValue to be set      
    }
  };  

  const getGameIsCompleted = () => {
    return (
      levelProgress.baum.completed &&
      levelProgress.nichtsistumsonst.completed &&
      levelProgress.weitergerannt.completed &&
      levelProgress.stoin.completed &&
      levelProgress.ichweissesnicht.completed
    )
  }

  useEffect(() => {
    if (getGameIsCompleted() && !successModalWasDisplayed) {
      // wait for zoom animation to be over:
      setTimeout(() => {
        setShowSuccessModal(true);
        setSuccessModalWasDisplayed(true);        
      }, 1000);
    }
  }, [getGameIsCompleted]);

  function handleLevelProgress (levelName, field) {
    setLevelProgress(prevState => ({
      ...prevState, 
      [levelName]: { ...prevState[levelName], [field]: true }
    }))
  }

  return (
    <div id="appContainer" className={`overflow-hidden ${isCursorHidden ? 'cursor-hidden' : ''}`}>
      <LevelProgressMeter
        isVisible={isUiVisible} 
        levelProgress={levelProgress} 
        gameIsCompleted={getGameIsCompleted()}
        handleClick={() => setShowSuccessModal(true)} 
      />
      <InfoDrawerButton 
        isVisible={showCover && isUiVisible} 
        handleClickInfoIcon={() => setShowInfoDrawer(true)} 
      />
      <HomeButton 
        isVisible={!showCover && isUiVisible}
        handleClick={() => toggleCover()} 
      />
      <div 
        className={`${zoomEffectIsActive ? 'zoom-effect' : ''} ${showCover ? 'translate-x-0' : 'translate-x-[100vw]'} justify-between absolute w-full h-full text-primary bg-secondary bg-noise`}
        style={{ transformOrigin: transformOrigin }}
      >
        <AlbumCover
          levelProgress={levelProgress}
          gameIsCompleted={getGameIsCompleted()}
          handleLevelClick={(event, newLevel) => handleLevelClick(event, newLevel)}
        />
      </div>
      
      <MusicPlayer
        isVisible={isUiVisible} 
        className="absolute right-5 !bg-neutral-900 bg-noise !bg-opacity-75 !text-white bottom-5 w-[calc(100vw-2.25rem)] z-10"
      />
      <InfoDrawer 
        isOpen={showInfoDrawer} 
        handleClose={() => setShowInfoDrawer(false)} 
      />
      <StartModal
        isOpen={showStartModal && !isDevMode}
        handleClose={() => setShowStartModal(false)}
      />
      <SuccessModal 
        isOpen={showSuccessModal} 
        gameIsCompleted={getGameIsCompleted()}
        handleClose={() => setShowSuccessModal(false)} 
        handleSpielregelnClick={() => setShowInfoDrawer(true)}
      />

      <PixiContainer 
        className={zoomEffectIsActive2 ? 'zoom-effect' : ''}
        style={{ transformOrigin: transformOrigin }}
        showLoadingBar={showLoadingBar && !showCover}
        loadingProgress={loadingProgress}
      />

      { spriteParams && isDevMode && !showCover && isUiVisible && (
        <GuiControls 
          spriteParams={spriteParams} 
          spriteOrder={spriteKeys} 
          spritesRef={spritesRef}
          isDevMode={isDevMode}
          setSpriteParams={setSpriteParams}
        />
      )}
    </div>
  );
};

export default App;
