import CloseButton from './CloseButton';

export default function HomeButton({ isVisible, handleClick, className }) {
  if (!isVisible) return null;
  
  return (  
    <div className={`${className} origin-bottom-left absolute right-4 sm:right-5 top-4 sm:top-5`}>
      <CloseButton 
        title="Zurück zur Startseite"
        size="32"
        className="z-10 flex justify-center items-center shadow-xl w-12 h-12 sm:w-12 sm:h-12 rounded-full !bg-slate-100 bg-noise !text-black max-w-2 hover:scale-110"
        handleClose={handleClick}
      />
    </div>
  )
}