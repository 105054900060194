export const levelSprites = [
  {
    fileName: 'faul1.webp',
    levelName: 'baum',
    sprite: null,
    x: -196,
    y: -941,
    sparkles: {
      show: false,
      offsetX: -300,
      offsetY: -350,
      maxWidth: 300,
      maxHeight: 300,
    }
  },
  {
    fileName: 'faul2.webp',
    levelName: 'ichweissesnicht',
    sprite: null,
    x: 750,
    y: -860,
    sparkles: {
      show: false,
      offsetX: -400,
      offsetY: -300,
      maxWidth: 300,
      maxHeight: 200,
    }
  },
  {
    fileName: 'faul3.webp',
    levelName: 'stoin',
    sprite: null,
    x: 121,
    y: -565,
    sparkles: {
      show: false,
      offsetX: -350,
      offsetY: -350,
      maxWidth: 200,
      maxHeight: 200,
    }    
  },
  {
    fileName: 'faul4.webp',
    levelName: 'nichtsistumsonst',
    sprite: null,
    x: 751,
    y: -435,
    sparkles: {
      show: false,
      offsetX: -400,
      offsetY: -200,
      maxWidth: 300,
      maxHeight: 100,
    }
  },
  {
    fileName: 'faul5.webp',
    levelName: 'weitergerannt',
    sprite: null,
    x: 57,
    y: -229,       
    sparkles: {
      show: false,
      offsetX: -280,
      offsetY: -200,
      maxWidth: 200,
      maxHeight: 200,
    }    
  },
]    