export const stoinConfig = {
    name: 'stoin',
    appBackground: '#ffffff',
    baseScale: {
      portrait: 0.11,
      landscape: 0.3
    },
    nextLevelThreshold: 5025511026090000, // set too high to work
    maxScaleLimit: {
      scale: 205596553745022,
      zoomLevel: 1
    },   
    selectedSprite: 'stoin1',
    spriteKeys: [
      "stoin1",
      "stoin2",
      "stoin3",
      "stoin4",
      "stoin5",
      "stoin5a",
      "stoin6",
      "stoin7",
      "stoin7a",
      "stoin8",
      "stoin9",
      "stoin10",
      "stoin11",
      "stoin12",
      "stoinFinal"
    ],
    spritesRef: {
      stoin1: null,
      stoin2: null,
      stoin3: null,
      stoin4: null,
      stoin5: null,
      stoin5a: null,
      stoin6: null,
      stoin7: null,
      stoin7a: null,
      stoin8: null,
      stoin9: null,
      stoin10: null,
      stoin11: null,
      stoin12: null,
      stoinFinal: null
    },
    spriteParams: [
      { // Start Planet
        name: 'stoin1',
        group: 'group1',
        anchor: 0.5,
        offsetX: 0,
        offsetY: 0,
        scale: 1
      },
      { // Mine
        name: 'stoin2',
        group: 'group1',
        offsetX: -1.2e+1,
        offsetY: -9.6e+1,
        scale: 1.9e-2,
      },  
      { // Stein mit Loch
        name: 'stoin3',
        group: 'group1',
        offsetX: 2.9e+0,
        offsetY: 1.3e+1,
        scale: 4.39e-3,
      },  
      { // Tisch
        name: 'stoin4',
        group: 'group1',
        offsetX: 4.12e+0,
        offsetY: 3.012e+0,
        scale: 2e-4,
      },  
      { // Mine Am Tisch
        name: 'stoin5',
        group: 'group1',
        offsetX: 1.64e-1,
        offsetY: 1.27e-1,
        scale: 3.9e-5,
      },  
      { // Mund Inneres
        name: 'stoin5a',
        group: 'group1',
        offsetX: 4.07e-2,
        offsetY: 2.77e-2,
        scale: 2e-7,
      },  
      { // Badewanne
        name: 'stoin6',
        group: 'group1',
        offsetX: 1.7e-4,
        offsetY: 9.18e-5,
        scale: 6e-8,
      },                                        
      { // Fels mit Höhle
        name: 'stoin7',
        group: 'group1',
        offsetX: 7.05e-5,
        offsetY: 1.51e-5,
        scale: 9.8e-9,
      },                                        
      { // Höhle Inneres
        name: 'stoin7a',
        group: 'group1',
        offsetX: 8.9e-6,
        offsetY: 9.93e-6,
        scale: 9.5e-10,
        rotate: 0.1, // add rotation
      },                                        
      { // UFO + Mine
        name: 'stoin8',
        group: 'group1',
        offsetX: 9.1e-7,
        offsetY: 6.4e-7,
        scale: 2.28e-10,
        rotate: 0.05, // add rotation
      },                                        
      { // UFO Inneres
        name: 'stoin9',
        group: 'group1',
        offsetX: 1.809e-7,
        offsetY: 5.925e-8,
        scale: 1.5e-12,
      },                                        
      { // Stein mit Loch
        name: 'stoin10',
        group: 'group1',
        offsetX: 1.42e-9,
        offsetY: 1.1e-9,
        scale: 9e-14,
      },                                        
      { // Untergrund Umarmung
        name: 'stoin11',
        group: 'group1',
        offsetX: 8.6e-11,
        offsetY: 7.9e-11,
        scale: 1.2e-14,
      },                                        
      { // Umarmung
        name: 'stoin12',
        group: 'group1',
        offsetX: 5.9e-12,
        offsetY: 4.5e-12,
        scale: 6e-15,
      },
      { // Final Image
        name: 'stoinFinal',
        group: 'group1',
        anchor: 0.5,
        offsetX: 6.89e-12,
        offsetY: 7.1e-12,
        scale: 5e-16 * 4,
        rotate: -0.4
      }                            
    ]
  }