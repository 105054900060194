export const ichweissesnichtConfig = {
    name: 'ichweissesnicht',
    appBackground: '#000000',
    baseScale: {
      portrait: 0.11,
      landscape: 0.3
    },
    nextLevelThreshold: 5025511026090000, // set too high to work
    maxScaleLimit: {
      scale: 130540809831559,
      zoomLevel: 1
    },    
    selectedSprite: 'ichweissesnicht1',
    spriteKeys: [
      "ichweissesnicht1",
      "ichweissesnicht2",
      "ichweissesnicht3",
      "ichweissesnicht4",
      "ichweissesnicht5",
      "ichweissesnicht6",
      "ichweissesnicht7",
      "ichweissesnichtFinal",
    ],
    spritesRef: {
      ichweissesnicht1: null,
      ichweissesnicht2: null,
      ichweissesnicht3: null,
      ichweissesnicht4: null,
      ichweissesnicht5: null,
      ichweissesnicht6: null,
      ichweissesnicht7: null,
      ichweissesnichtFinal: null
    },
    spriteParams: [
      { // Haus
        name: 'ichweissesnicht1',
        group: 'group1',
        anchor: 0.5,
        offsetX: 0,
        offsetY: 0,
        scale: 1,
      },
      { // Haus
        name: 'ichweissesnicht2',
        group: 'group1',
        anchor: 0.5,
        offsetX: 3.6e+1,
        offsetY: -2.3e+1,
        scale: 8e-3,
      },
      { // Haus
        name: 'ichweissesnicht3',
        group: 'group1',
        anchor: 0.5,
        offsetX: 2.95e-1,
        offsetY: 3.98e+0,
        scale: 1.4e-5,
      },
      { // Haus
        name: 'ichweissesnicht4',
        group: 'group1',
        anchor: 0.5,
        offsetX: -2.1e-4,
        offsetY: -1.4e-4,
        scale: 6e-8,
      },
      { // Haus
        name: 'ichweissesnicht5',
        group: 'group1',
        anchor: 0.5,
        offsetX: -4.7e-7,
        offsetY: 1.4e-6,
        scale: 3.5e-10,
      },
      { // Haus
        name: 'ichweissesnicht6',
        group: 'group1',
        anchor: 0.5,
        offsetX: 1e-9,
        offsetY: -2.3e-8,
        scale: 6e-12,
      },
      { // Haus
        name: 'ichweissesnicht7',
        group: 'group1',
        anchor: 0.5,
        offsetX: 1.1e-11,
        offsetY: -1.8e-10,
        scale: 1e-13,
      },
      { // Final Image
        name: 'ichweissesnichtFinal',
        group: 'group1',
        anchor: 0.5,
        offsetX: -8.4e-12,
        offsetY: -2.35e-11,
        scale: 1.3e-15 * 4,
      }
    ]
  }
