import LoadingBar from "./LoadingBar"

export default function pixiContainer({ 
  className, 
  style,
  showLoadingBar,
  loadingProgress,
}) {
  return (
    <>
      <div 
        id="pixiContainer"
        className={className}
        style={{...style, width: '100vw', height: '100vh'}} 
        draggable="false" 
      />
      <LoadingBar 
        loadingProgress={loadingProgress}
        isVisible={showLoadingBar}
      />      
    </>
  )
}