import { useState } from 'react'

export default function SuccessModal ({ isOpen, handleClose }) {

  const [startZoomEffect, setStartZoomEffect] = useState(false);

  async function handleClick() {
    setStartZoomEffect(true);
    setTimeout(() => {
      handleClose();
    }, 1000);
    try {
      const response = await fetch('https://baum.minemusik.de/clickEvents.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          'name': 'clickStartButton',
          'context': ''
        })
      });
    } catch (error) {
      console.error('Error:', error);
    }     
  }

  if (!isOpen) return;

  return (
    <>
      {isOpen && ( // dark overlay
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-50"></div>
      )}
      <div 
        className={`${startZoomEffect ? 'zoom-effect' : ''} absolute rounded-xl top-[5vh] w-[90vw] max-w-xl transform ease-in-out duration-300 overflow-hidden z-50`}
        style={{ transformOrigin: 'center 50px' }}
      >
        <div className="max-h-[90dvh] sm:max-h-[90dvh] flex flex-col items-center pt-6 pb-9 sm:py-12 text-primary bg-secondary bg-noise">
          <div className="flex flex-col overflow-auto p-6 sm:px-12">
            <div 
              className={`w-2/3 !shrink-0 !aspect-square self-center rounded-xl border-4 shadow-lg border-yellow-700`} 
              style={{ 
                backgroundImage: 'url(/assets/cover/album-cover-dirty.webp)',
                backgroundColor: 'rgba(0,0,0,0.2)',
                backgroundSize: 'cover',
              }}
            />
            <section className="mt-6 sm:mt-12 mb-5 sm:mb-10 pb-16 sm:pb-10 text-lg sm:text-xl">
              <h1 className="mb-2">
                Oh no!
              </h1>
              <p className="mb-2">
                Das Cover meines neuen Albums BAUM ist von Fäulnis befallen 😵‍!
              </p>
              <p className="mb-2">
                Rette es, indem du dich durch die illustrierte Welt des Albums scrollst und die fünf Zauberblätter einsammelst.
              </p>
              <p>
                Wenn du es schaffst und den “BAUM“ vom Moder befreist, hast du die Chance zum Release am 2.2.2024 ein fettes Paket inkl. signiertem Album, zwei Gästelistenplätzen und Merchandise zu gewinnen!
              </p>
            </section>
            <button
              className="fixed bottom-8 left-5 right-5 bg-primary text-secondary font-bold p-3 sm:my-0 rounded-xl shadow-[0_-10px_20px_currentColor] shadow-secondary"
              onClick={handleClick}
            >
              START
            </button>
          </div>
        </div>
      </div>    
    </>
  );
}