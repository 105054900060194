import { useEffect } from 'react';
import { GUI } from 'dat.gui';

const GuiControls = ({ spriteParams, spriteOrder, spritesRef, isDevMode }) => {
  useEffect(() => {
    const gui = new GUI();
    const spriteControls = gui.addFolder('Sprite Controls');

    const updateSpritePosition = (spriteName) => {
      const spriteParam = spriteParams.find(param => param.name === spriteName);
      const previousIndex = spriteParams.findIndex(param => param.name === spriteName) - 1;

      if (previousIndex >= 0 && spritesRef.current[spriteParams[previousIndex].name]) {
        const currentSprite = spritesRef.current[spriteName];
        const previousSprite = spritesRef.current[spriteParams[previousIndex].name];
        currentSprite.x = previousSprite.x + spriteParam.offsetX;
        currentSprite.y = previousSprite.y + spriteParam.offsetY;
      }
    };

    const modifyControllerDisplay = (controller) => {
      const originalUpdateDisplay = controller.updateDisplay;

      controller.updateDisplay = function() {
        originalUpdateDisplay.call(controller);
        controller.domElement.querySelector('input').value = controller.object[controller.property].toExponential();
      };
    };

    spriteOrder.forEach(spriteName => {
      const spriteControlFolder = spriteControls.addFolder(spriteName);
      const spriteParam = spriteParams.find(param => param.name === spriteName);

      // Modify the offsetX control
      const offsetXController = spriteControlFolder.add(spriteParam, 'offsetX').name('Offset X');
      modifyControllerDisplay(offsetXController);
      offsetXController.onChange(newValue => {
        updateSpritePosition(spriteName);
      });

      // Modify the offsetY control
      const offsetYController = spriteControlFolder.add(spriteParam, 'offsetY').name('Offset Y');
      modifyControllerDisplay(offsetYController);
      offsetYController.onChange(newValue => {
        updateSpritePosition(spriteName);
      });

      // Modify the scale control
      const scaleController = spriteControlFolder.add(spriteParam, 'scale').name('Scale');
      modifyControllerDisplay(scaleController);
      scaleController.onChange(newValue => {
        spritesRef.current[spriteName].scale.set(newValue);
      });
    });

    return () => gui.destroy(); // Cleanup the GUI when the component is unmounted
  }, [spriteParams, isDevMode]);

  return null;  // This component doesn't render anything to the DOM directly
}

export default GuiControls;
