export const nichtsistumsonstConfig = {
    name: 'nichtsistumsonst',
    appBackground: '#ffffff',
    baseScale: {
      portrait: 0.11,
      landscape: 0.3
    },
    nextLevelThreshold: 38898202713,
    maxScaleLimit: {
      scale: 27354302465920,
      zoomLevel: 2
    },
    selectedSprite: 'nichtsistumsonst1',
    spriteKeys: [
      "nichtsistumsonst1",
      "nichtsistumsonst2",
      "nichtsistumsonst3",
      "nichtsistumsonst4",
      "nichtsistumsonst4a",
      "nichtsistumsonst5",
      "nichtsistumsonst5a",
      "nichtsistumsonst6",
      "giftSprite",
      "nichtsistumsonst7",
      "nichtsistumsonst8",
      "nichtsistumsonst9",
      "nichtsistumsonst10Transition",
      "nichtsistumsonst10",
      "nichtsistumsonst11",
      "nichtsistumsonst12",
      "nichtsistumsonst13",
      "nichtsistumsonst14",
      "nichtsistumsonst15",
      "nichtsistumsonst16",
      "nichtsistumsonst17a",
      "nichtsistumsonst17b",
      "nichtsistumsonst18",
      "nichtsistumsonst19",
      "nichtsistumsonst19a",
      "nichtsistumsonst20",
      "nichtsistumsonstFinal"
    ],
    spritesRef: {
      nichtsistumsonst1: null,
      nichtsistumsonst2: null,
      nichtsistumsonst3: null,
      nichtsistumsonst4: null,
      nichtsistumsonst4a: null,
      nichtsistumsonst5: null,
      nichtsistumsonst5a: null,
      nichtsistumsonst6: null,
      giftSprite: null,
      nichtsistumsonst7: null,
      nichtsistumsonst8: null,
      nichtsistumsonst9: null,
      nichtsistumsonst10Transition: null,
      nichtsistumsonst10: null,
      nichtsistumsonst11: null,
      nichtsistumsonst12: null,
      nichtsistumsonst13: null,
      nichtsistumsonst14: null,
      nichtsistumsonst15: null,
      nichtsistumsonst16: null,
      nichtsistumsonst17a: null,
      nichtsistumsonst17b: null,
      nichtsistumsonst18: null,
      nichtsistumsonst19: null,
      nichtsistumsonst19a: null,
      nichtsistumsonst20: null,
      nichtsistumsonstFinal: null
    },
    spriteParams: [
      { // Laden "Zeit"
        name: 'nichtsistumsonst1',
        group: 'group1',
        anchor: 0.5,
        offsetX: 0,
        offsetY: 0,
        scale: 1,
      },
      { // Schaufenster Inhalt
        name: 'nichtsistumsonst2',
        group: 'group1',
        anchor: 0.5,
        offsetX: -1.82e+2,
        offsetY: 3.8e+1,
        scale: 4.21e-1,
      },
      { // Computer
        name: 'nichtsistumsonst3',
        group: 'group1',
        anchor: 0.5,
        offsetX: -1.8e+2,
        offsetY: 1.04e+2,
        scale: 9.7e-2,
      },
      { // Bildschirm Inhalt
        name: 'nichtsistumsonst4',
        group: 'group1',
        anchor: 0.5,
        offsetX: 4.8e+0,
        offsetY: -3.9e+1,
        scale: 4.68e-2,
      },
      { // Player Selection
        name: 'nichtsistumsonst4a',
        group: 'group1',
        anchor: 0.5,
        offsetX: 5.2e-1,
        offsetY: 2.5e+1,
        scale: 1.4e-2,
      },
      { // Player Selection Visual
        name: 'nichtsistumsonst5',
        group: 'group1',
        anchor: 0.5,
        offsetX: 3.33e+0,
        offsetY: -1.157e+0,
        scale: 2.09e-4,
      },
      { // Start Button
        name: 'nichtsistumsonst5a',
        group: 'group1',
        anchor: 0.5,
        offsetX: 0.003,
        offsetY: -1.18e-1,
        scale: 4.9e-5,
      },
      { // Berg Level
        name: 'nichtsistumsonst6',
        group: 'group1',
        anchor: 0.5,
        offsetX: -2.6e-3,
        offsetY: 7.62e-4,
        scale: 1.3e-6,
      },
      { // GESCHENK SPRITE GEWINNSPIEL
        name: 'giftSprite',
        group: 'group1',
        anchor: 0.5,
        offsetX: 3.1e-4,
        offsetY: -3.1e-4,
        scale: 3e-7,
        link: 'https://open.spotify.com/track/3qMRbIPQ19kswPA1j587fb',
      },
      { // Bunker
        name: 'nichtsistumsonst7',
        group: 'group1',
        anchor: 0.5,
        offsetX: -1.92e-4,
        offsetY: -3.58e-4,
        scale: 5e-7,
      },
      { // Dino Insel Level
        name: 'nichtsistumsonst8',
        group: 'group1',
        anchor: 0.5,
        offsetX: 1.525e-4,
        offsetY: -1.8e-5,
        scale: 1.87e-8,
      },
      { // Baum mit Loch
        name: 'nichtsistumsonst9',
        group: 'group1',
        anchor: 0.5,
        offsetX: -3.3e-6,
        offsetY: -9.3e-6,
        scale: 2.55e-9,
      },
      { // Level Felsenland 1
        name: 'nichtsistumsonst10Transition',
        group: 'group1',
        anchor: 0.5,
        offsetX: -2.12e-7,
        offsetY: -1.68e-7,
        scale: 6.9e-12,
      },
      { // Level Felsenland 2
        name: 'nichtsistumsonst10',
        group: 'group2',
        anchor: 0.5,
        offsetX: 3.6e+2, // I don't know why, but this is the only way to get the sprite to be positioned correctly 
        offsetY: -1.3e+2, // I don't know why, but this is the only way to get the sprite to be positioned correctly 
        scale: 0,
      },        
      { // Fels
        name: 'nichtsistumsonst11',
        group: 'group2',
        anchor: 0.5,
        offsetX: 7.1e+1,
        offsetY: -5.42e+2,
        scale: 1.4e-1,
        portrait: {
          offsetX: 2.01e+2,
          offsetY: -1.482e+3,
          scale: 3.8e-1
        }
      },
      { // Level Betonboden
        name: 'nichtsistumsonst12',
        group: 'group2',
        anchor: 0.5,
        offsetX: -2.39e+1,
        offsetY: 2.9e+1,
        scale: 4.82e-3,     
        portrait: {
          offsetX: -6.5e+1,
          offsetY: 7.9e+1,
          scale: 1.282e-2,  
        }             
      },
      { // Flugzeug
        name: 'nichtsistumsonst13',
        group: 'group2',
        anchor: 0.5,
        offsetX: -2.08e+0,
        offsetY: -2.84e+0,
        scale: 9.75e-4,
        portrait: {
          offsetX: -5.38e+0,
          offsetY: -7.74e+0,
          scale: 2.515e-3,          
        }          
      },
      { // Level Super Mario
        name: 'nichtsistumsonst14',
        group: 'group2',
        anchor: 0.5,
        offsetX: -1.07e-1,
        offsetY: -1.26e-1,
        scale: 3.46e-5,       
        portrait: {
          offsetX: -2.77e-1,
          offsetY: -3.26e-1,
          scale: 8.86e-5,            
        }                     
      },
      { // Haufen 
        name: 'nichtsistumsonst15',
        group: 'group2',
        anchor: 0.5,
        offsetX: -2.31e-2,
        offsetY: -2.525e-2,
        scale: 3e-6,       
        portrait: {
          offsetX: -5.91e-2,
          offsetY: -6.47e-2,
          scale: 7.8e-6,              
        }                     
      },
      { // Level blutige Hände
        name: 'nichtsistumsonst16',
        group: 'group2',
        anchor: 0.5,
        offsetX: 2.15e-4,
        offsetY: 2.85e-4,
        scale: 8.3e-8,      
        portrait: {
          offsetX: 5.55e-4,
          offsetY: 7.55e-4,
          scale: 1.99e-7,         
        }                           
      },
      { // Herz rechts
        name: 'nichtsistumsonst17a',
        group: 'group2',
        anchor: 0.5,
        offsetX: 1.1e-5,
        offsetY: -3.65e-5,
        scale: 5.15e-9,   
        portrait: {
        offsetX: 2.6e-5,
        offsetY: -8.85e-5,
        scale: 1.335e-8,        
        }                         
      },
      { // Herz links
        name: 'nichtsistumsonst17b',
        group: 'group2',
        anchor: 0.5,
        offsetX: -1.1e-5,
        offsetY: -4e-7,
        scale: 5.15e-9,
        flipX: true, // custom property to flip sprite horizontally          
        portrait: {
          offsetX: -2.6e-5,
          offsetY: -7.1e-7,
          scale: 1.335e-8,        
        }                  
      },
      { // Computer
        name: 'nichtsistumsonst18',
        group: 'group2',
        anchor: 0.5,
        offsetX: 1.5308e-5,
        offsetY: -2.18e-6,
        scale: 2e-11,
        portrait: {
          offsetX: 3.717e-5,
          offsetY: -5.98e-6,
          scale: 5.2e-11,
        }          
      },                                
      { // Game Over Screen
        name: 'nichtsistumsonst19',
        group: 'group2',
        anchor: 0.5,
        offsetX: 1.1e-9,
        offsetY: -7.7e-9,
        scale: 9e-12,
        portrait: {
          offsetX: 2.9e-9,
          offsetY: -1.94e-8,
          scale: 2.43e-11,
        }          
      },
      { // Exit Button
        name: 'nichtsistumsonst19a',
        group: 'group2',
        anchor: 0.5,
        offsetX: 0,
        offsetY: 4.4e-9,
        scale: 2.8e-12,        
        portrait: {
          offsetX: 0,
          offsetY: 1.19e-8,
          scale: 7.5e-12,          
        }  
      },
      { // Zeitladen
        name: 'nichtsistumsonst20',
        group: 'group2',
        anchor: 0.5,
        offsetX: -5.4e-10,
        offsetY: 1e-11,
        scale: 5e-14,     
        portrait: {
          offsetX: -1.44e-9,
          offsetY: 2.1e-11,
          scale: 1.56e-13,          
        }     
      },
      { // Final Image
        name: 'nichtsistumsonstFinal',
        group: 'group2',
        anchor: 0.5,
        offsetX: -3.7e-11,
        offsetY: 1.35e-11,
        scale: 1.2e-14,
        rotate: 1.2,
        portrait: {
          offsetX: -1.15e-10,
          offsetY: 4.2e-11,
          scale: 4.2e-14,
          rotate: 1.2,
        }
      }                                        
    ]
  }
