import { useEffect, useState } from "react";
import LevelProgressMeterItem from "./LevelProgressMeterItem";
import LevelProgressBar from "./LevelProgressBar"
import SparkleIcon from "./icons/SparkleIcon";

export default function LevelProgressMeter({ isVisible, levelProgress, gameIsCompleted, handleClick }) {

  const [progress, setProgress] = useState(0); // in percent
  
  useEffect(() => {
    let progress = 0;
    const levels = Object.values(levelProgress);
    levels.forEach(level => {
      if (level.completed) {
        progress += 20; // increase progress by 20% for each completed level
      }
    })
    setProgress(progress);
  }, [levelProgress])
  
  if (!isVisible) return null;
  
  return (
    <>
      <button
        className="absolute left-4 sm:left-5 top-4 sm:top-5 z-10 flex items-center justify-between select-none bg-neutral-900 bg-noise bg-opacity-75 p-2 brightness-110 shadow-lg rounded-full text-4xl origin-bottom-left"
        onClick={handleClick}
      >
        <LevelProgressBar
          className="mr-2"
          scale={0.7}
          progress={progress}
          gameIsCompleted={gameIsCompleted}
        />
        <LevelProgressMeterItem 
          isCompleted={levelProgress.weitergerannt.completed} 
          isFadedOut={levelProgress.weitergerannt.fadedOut}
          imageSrc="./assets/icons/weitergerannt_244.webp"
        />
        <LevelProgressMeterItem
          className="left-[2px]" 
          isCompleted={levelProgress.nichtsistumsonst.completed} 
          isFadedOut={levelProgress.nichtsistumsonst.fadedOut}
          imageSrc="./assets/icons/nichtsistumsonst_244.webp"
        />
        <LevelProgressMeterItem 
          className="top-[1px]"
          isCompleted={levelProgress.stoin.completed}
          isFadedOut={levelProgress.stoin.fadedOut} 
          imageSrc="./assets/icons/stoin_244.webp"
        />
        <LevelProgressMeterItem 
          className="top-[1px]"
          isCompleted={levelProgress.ichweissesnicht.completed}
          isFadedOut={levelProgress.ichweissesnicht.fadedOut}
          imageSrc="./assets/icons/ichweissesnicht_244.webp"
        />
        <LevelProgressMeterItem 
          className="top-[1px]"
          isCompleted={levelProgress.baum.completed} 
          isFadedOut={levelProgress.baum.fadedOut}
          imageSrc="./assets/icons/baum_244.webp"
        />
        <span className={`${gameIsCompleted ? 'add-single-sparkle' : ''} translate-x-5 -translate-y-4 relative w-0 h-0 text-3xl`}>
          🏆
          <SparkleIcon 
            className={'hidden absolute -top-2 -right-8 w-5 h-5'}
            color="#FFFFFF"
            />            
          <SparkleIcon 
            className={'hidden absolute -top-1 -right-6 w-3 h-3'}
            color="#FFFFFF"
            style={{ animationDelay: '600ms' }}
          />           
        </span>
        {/* <img className="absolute -right-6 translate-x-5 w-8 h-8 rounded-lg" src="./assets/gewinnspiel.jpeg" /> */}
        {/* <div className="flex items-center bg-red-500 h-8">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-6 h-6 absolute -right-8 text-yellow-600">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
          </svg>          
        </div> */}

      </button>
    </>
  )
}