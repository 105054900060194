export const weitergeranntConfig = {
    name: 'weitergerannt',
    appBackground: '#ffffff',
    baseScale: {
      portrait: 0.11,
      landscape: 0.3
    },
    nextLevelThreshold: 5025511026090000, // set too high to work
    maxScaleLimit: {
      scale: 30722169683,
      zoomLevel: 1
    },    
    selectedSprite: 'weitergerannt1',
    spriteKeys: [
      "weitergerannt1",
      "weitergerannt2",
      "weitergerannt3",
      "weitergerannt4",
      "weitergerannt5",
      "weitergerannt6",
      "weitergeranntFinal"
    ],
    spritesRef: {
      weitergerannt1: null,
      weitergerannt2: null,
      weitergerannt3: null,
      weitergerannt4: null,
      weitergerannt5: null,
      weitergerannt6: null,
      weitergeranntFinal: null
    },
    spriteParams: [
      { // Haus
        name: 'weitergerannt1',
        group: 'group1',
        anchor: 0.5,
        offsetX: 0,
        offsetY: 0,
        scale: 1,
      },
      { // Haus
        name: 'weitergerannt2',
        group: 'group1',
        anchor: 0.5,
        offsetX: -6.8e+1,
        offsetY: -13,
        scale: 2.6e-2,
      },
      { // Haus
        name: 'weitergerannt3',
        group: 'group1',
        anchor: 0.5,
        offsetX: -6.5e-1,
        offsetY: -1.5e-1,
        scale: 8.3e-4,
      },
      { // Haus
        name: 'weitergerannt4',
        group: 'group1',
        anchor: 0.5,
        offsetX: 1.765e-1,
        offsetY: -4.2e-1,
        scale: 6.1e-6,
      },
      { // Haus
        name: 'weitergerannt5',
        group: 'group1',
        anchor: 0.5,
        offsetX: 1.085e-3,
        offsetY: -1.53e-3,
        scale: 4.2e-8,
      },
      { // Haus
        name: 'weitergerannt6',
        group: 'group1',
        anchor: 0.5,
        offsetX: -4.75e-7,
        offsetY: -1.46e-5,
        scale: 8.8e-11,
      },
      { // Final Image
        name: 'weitergeranntFinal',
        group: 'group1',
        anchor: 0.5,
        offsetX: 2.2e-9,
        offsetY: -3e-9,
        scale: 7e-12 * 4,
        rotate: -0.4
      }
    ]
  }
