import DeleteIcon from '../icons/DeleteIcon'

export default function CloseButton({ className, handleClose, size, title }) {
  return (
    <button
      title={title}
      className={`bg-transparent border-0 outline-none text-xl text-primary ${className}`} 
      onClick={handleClose}
    >
      <DeleteIcon size={size} />
    </button>    
  )
}