export default function LoadingBar({ loadingProgress, isVisible }) {
  if (!isVisible) return null;

  return (
    <div className="absolute h-full flex flex-col items-center justify-center w-[80vw] max-w-md">
      <div className="w-full flex flex-col items-center py-5 px-5 rounded-xl bg-secondary bg-noise bg-opacity-7">
        <label className="flex items-center justify-start">
          <svg className="animate-spin mr-2 h-4 w-4 mb-3 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <p className="text-primary mb-3 font-bold text-2xl">{ `${loadingProgress}%` }</p>
        </label>
        <div className="bg-slate-100 flex w-full rounded overflow-hidden">
          <div className="bg-primary h-3 min-w-[3px]" style={{ width: `${loadingProgress}%` }}></div>
          <div className="bg-slate-200 h-3" style={{ width: `${100 - loadingProgress}%`}}></div>            
        </div>        
      </div>
    </div>
  );
}