import VolumeIcon from "./icons/VolumeIcon";
import { useEffect, useRef, useState } from "react";

export default function MusicPlayer ({ isVisible, className }) {

  const audioRef = useRef(new Audio('./assets/audio/NIU_INST.mp3'));
  audioRef.current.loop = true;
  const [isMuted, setIsMuted] = useState(true);
  const marqueeContainer = useRef(null);
  const marqueeShadowText = useRef(null);

  useEffect(() => {
    isMuted ? audioRef.current.pause() : audioRef.current.play();
  }, [isMuted])  

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && audioRef.current && !audioRef.current.paused) {  
        const fadeAudio = setInterval(() => {
          if (audioRef.current.volume > 0.1) {
            audioRef.current.volume -= 0.1;
          } else {
            clearInterval(fadeAudio);
            audioRef.current.pause();
            setIsMuted(true);
            audioRef.current.volume = 1; // Reset volume for next play
          }
        }, 50);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);  

  useEffect(() => {
      const delta = (marqueeContainer.current.clientWidth - 24) - marqueeShadowText.current.clientWidth;
      if (delta >= 0) return;
      const styleSheet = document.createElement('style');
      styleSheet.innerText = `
        @keyframes marquee {
          0% { transform: translateX(0%); }
          20%, 80% { transform: translateX(${delta}px); }
          90%, 100% { transform: translateX(0%); }
        }
      `;
      document.head.appendChild(styleSheet);

      return () => {
        document.head.removeChild(styleSheet);
      };
  }, []);  

  if (!isVisible) return null;

  return (
    <div className={`bg-secondary select-none p-3 rounded-xl sm:max-w-xs max-w-xl shadow-lg flex items-center justify-start ${className}`}>
      <img 
        src="./assets/cover/Album-Cover_Baum_2.webp"
        className="w-10 h-10 rounded-lg"
      />
      <div ref={marqueeContainer} className="flex flex-grow w-2/3 flex-col text-sm px-3 text-primary">
        <div className="overflow-hidden whitespace-nowrap">
          <p className={!isMuted ? 'animate-[marquee_10s_linear_infinite]' : 'truncate'}>
            Nichts Ist Umsonst (Instrumental)
          </p>
          {/* Add a invisible shadow text to get full width of text: */}
          <span ref={marqueeShadowText} className="invisible absolute">
            Nichts Ist Umsonst (Instrumental)
          </span>
        </div>
        <p className="text-slate-200">
          Baum (<a className="underline underline-offset-2" href="https://lnk.to/MineBAUM" target="_blank">jetzt vorbestellen</a>)
        </p>
      </div>
      <button
        className="ml-auto text-slate-200"
        onClick={() => setIsMuted(!isMuted)}
      >
        <VolumeIcon isMuted={isMuted} />
      </button>
    </div>
  )
}